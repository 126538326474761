import React from 'react';
import { composeValidators, required } from '../../../utils';
import { AiOutlineCreditCard } from 'react-icons/ai'

const PAYMENT_TYPE_ID = 'sotiaf.payments.system_pay';

import { Input } from '../../form';
import { Field } from 'react-final-form';

export function SystemPay({ onClick, isSinglePaymentMethod }) {
  return (
    <>
      <Field
        component={Input}
        key={PAYMENT_TYPE_ID}
        label={<div className='flex flex-row items-center'><AiOutlineCreditCard />&nbsp;Paiement par carte</div>}
        type="radio"
        name="gateway"
        value={PAYMENT_TYPE_ID}
        onClick={onClick}
        initialValue={isSinglePaymentMethod ? PAYMENT_TYPE_ID : null}
        validate={composeValidators(required)}
      />
    </>
  );
}
