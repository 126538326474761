import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
const baseUrl = `${serverRuntimeConfig.API_PRESSE_URL}/order`;

import { axiosInstance } from '../../utils';

const createOrder = async (values) => {
  try {
    return await axiosInstance.axiosPostFetcher(baseUrl, values);
  } catch (err) {
    throw err;
  }
};

export const orderPresseService = {
  createOrder,
};
