let store = {};

const serverStore = {};

serverStore.set = (key, value) => {
  store = { ...store, [key]: value };
};

serverStore.get = (key) => {
  return store[key];
};

export default serverStore;
