import { useEffect, createContext, useContext, useState } from "react"
import "vanilla-cookieconsent/dist/cookieconsent.js"
import "vanilla-cookieconsent/dist/cookieconsent.css"

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Script from 'next/script';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export function CookieConsent({ children }) {
  const [accept, setAccept] = useState([]);

  useEffect(() => {
      if (!document.getElementById('cc--main')) {
          window.CookieConsentApi = window.initCookieConsent();
          window.CookieConsentApi.run({
            onAccept: ({ categories }) => {
              setAccept(categories)
            },
            page_scripts: true,
            autoclear_cookies: true,
            cookie_expiration: 182,
            current_lang: "fr",
            gui_options: {
              consent_modal: {
                layout: 'cloud',               // box/cloud/bar
                position: 'bottom center',     // bottom/middle/top + left/right/center
                transition: 'slide',           // zoom/slide
                swap_buttons: false            // enable to invert buttons
              },
              settings_modal: {
                layout: 'box',                 // box/bar
                // position: 'left',           // left/right
                transition: 'slide'            // zoom/slide
              },
            },
            languages: {
              fr: {
                consent_modal: {
                  title: 'Le respect de votre vie privée est notre priorité',
                  description: "Bonjour, ce site utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies optionnels de suivi. Ce dernier ne sera mise en place qu'après approbation de votre part.<br/><a aria-label='Cookie policy' className='cc-link' href='/mentions-legales'>En savoir plus</a>",
                  primary_btn: {
                    text: 'Tout accepter',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                  },
                  secondary_btn: {
                    text: 'Réglages',
                    role: 'settings'                // 'settings' or 'accept_necessary'
                  }
                },
                settings_modal: {
                  title: 'Préférences en matière de cookies',
                  save_settings_btn: 'Enregistrer',
                  accept_all_btn: 'Tout accepter',
                  reject_all_btn: 'Tout rejeter',       // optional, [v.2.5.0 +]
                  cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'},
                    {col5: 'Type'}
                  ],
                  blocks: [
                    {
                      title: 'Utilisation des cookies',
                      description: `
                      Lorsque vous consultez un site Web, des données sont stockées dans votre navigateur ou récupérées à partir de celui-ci, généralement sous la forme de cookies.
                      <br/>
                      Certaines de ces données sont strictement nécessaires au fonctionnement du site ou nécessaires à la fourniture d'un service à votre demande et ne sont pas soumis au recueil de votre consentement. Concernant les autres cookies ou traceurs, ils doivent être acceptés par vous.
                      `
                    },
                    {
                      title: 'Cookie strictement nécessaire',
                      description: `
                      Ces cookies sont nécessaires au bon fonctionnement du site Web et ne peuvent pas être désactivés. Ils sont généralement établis suite à des actions que vous avez effectuées et qui constituent une demande de services, telles que la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires.
                      `,
                      toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true
                      }
                    },
                    {
                      title: 'Analytiques',
                      description: `
                      Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site Web. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web. Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n'acceptez pas ces cookies, nous ne serons pas informés de votre visite sur notre site.
                      `,
                      toggle: {
                        value: 'analytics',
                        enabled: false,
                        readonly: false
                      },
                      cookie_table: [
                        {
                            col1: '^_ga',
                            col2: 'google.com',
                            col3: '2 years',
                            col4: 'Associer à Google Analytics pour son bon fonctionnement',
                            col5: 'Cookie permanent',
                            is_regex: true
                        },
                        {
                            col1: '_gid',
                            col2: 'google.com',
                            col3: '1 day',
                            col4: 'Associer à Google Analytics pour son bon fonctionnement',
                            col5: 'Cookie permanent'
                        }
                    ]
                    }, 
                    // {
                    //   title: 'More information',
                    //   description: 'For any queries in relation to my policy on cookies and your choices, please <a className="cc-link" href="#yourwebsite">contact me</a>.',
                    // }
                  ]
                }
              }
            }
          });
      }

  }, []);

  return (
    <>
      {accept && accept.includes('analytics') &&
        <>
          <Script data-cookiecategory="analytics" async src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GA_KEY}`} />
          <Script data-cookiecategory="analytics" id="google-analytics">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${publicRuntimeConfig.GA_KEY}');
            `}
          </Script>
        </>
      }
      <GoogleReCaptchaProvider
        reCaptchaKey={publicRuntimeConfig.PUBLIC_CAPTCHA}
        scriptProps={{
          async: false, // optional, default to false,
          defer: true, // optional, default to false
          appendTo: "body", // optional, default to "head", can be "head" or "body",
          nonce: undefined,
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
    </>
  );
}