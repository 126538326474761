import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const baseUrl = `${publicRuntimeConfig.API_MAGAZINE_URL}/article`;

const URL_LAST_PLUS = `${baseUrl}/PAE?limit=1`;
const URL_PLUS = `${baseUrl}/PAE`;
const URL_BY_URL = `${baseUrl}/url/`;

import { axiosInstance } from '../../utils';

const getByUrl = async (url) => {
  try {
    return await axiosInstance.axiosGetFetcher(`${URL_BY_URL}${url}`);
  } catch (err) {
    throw err;
  }
};

const getLastLePlus = async () => {
  try {
    return await axiosInstance.axiosGetFetcher(`${URL_LAST_PLUS}`);
  } catch (err) {
    throw err;
  }
};

const getLePlus = async (limit = 2, offset = 0, search = "") => {
  try {
    if (!search || !search.trim()) search = ""
    return await axiosInstance.axiosGetFetcher(`${URL_PLUS}?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ''}`);
  } catch (err) {
    throw err;
  }
};


export const leplusMagazineService = { getLastLePlus, getLePlus, getByUrl, URL_BY_URL, URL_LAST_PLUS, URL_PLUS };
