import { Fragment } from 'react';
import { Button } from '../Button';
import Image from 'next/image';

export function LePlus({ lastLePlus }) {
  return (
    <Fragment>
      <h2 className="flex items-center inline mb-5 text-5xl font-bold uppercase text-blue-dark">
        <span className="hidden w-40 h-1 mr-10 bg-blue-dark sm:block"></span>
        Le&nbsp;<b>plus</b>&nbsp;A&E
      </h2>
      <div>
        <Image
          className="w-full"
          src={lastLePlus.picture.presignedUrl}
          alt="Image présentant l'article Le Plus d'Animation et Éducation"
          width={1200}
          height={400}
          style={{
            maxWidth: '100%',
            height: 'auto',
            width: 'auto'
          }}
        />
        <div className="my-7">
          <h3 className="text-5xl italic">{lastLePlus.title}</h3>
          <p>
            {lastLePlus.description}
          </p>
        </div>
        <div className="flex flex-col justify-between xs:flex-row">
          <Button
            link={true}
            action={`/le-plus/${lastLePlus.urlRewrite}`}
            text="Lire l'article"
            color="blue-dark"
            plain={false}
            className="px-[10%] py-4 text-xl mb-5 xs:mb-0 xs:mr-2"
          />
          <Button
            link={true}
            action="/le-plus"
            text="Consulter la rubrique"
            color="pink"
            plain={false}
            className="px-[10%] py-4 text-xl xs:ml-2"
          />
        </div>
      </div>
    </Fragment>
  );
}
