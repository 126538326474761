import getConfig from 'next/config';
import { gql } from '@apollo/client';
import { init, query, mutation, getIssueNumber } from '../../utils';
import { setCookie, getCookie, deleteCookie, hasCookie } from 'cookies-next';
import { customerSaleor } from './customer.saleor.service';
import { individuPresseService } from '../presse';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const COOKIE_NAME = 'cart-token';

const CHECKOUT_QUERY = `
  id
  email
  user {
    id
    metadata {
      key 
      value
    }
    defaultShippingAddress {
      id
      firstName
      lastName
      companyName
      streetAddress1
      streetAddress2
      city
      cityArea
      postalCode
      country {
        code
        country
      }
      countryArea
      phone
    }
    defaultBillingAddress {
      id
      firstName
      lastName
      companyName
      streetAddress1
      streetAddress2
      city
      cityArea
      postalCode
      country {
        code
        country
      }
      countryArea
      phone
    }
  }
  lines {
    id
    quantity
    unitPrice {
      gross {
        amount
        currency
      }
    }
    metadata {
      key
      value
    }
    totalPrice {
      gross {
        amount
        currency
      }
    }
    variant {
      id
      product {
        id
        name
        slug
        thumbnail {
          url
          alt
        }
      }
      pricing {
        price {
          gross {
            amount
            currency
          }
        }
      }
      name
    }
  }
  shippingAddress {
    firstName
    lastName
    streetAddress1
    streetAddress2
    companyName
    city
    cityArea
    postalCode
    country {
      code
      country
    }
    countryArea
    phone
  }
  billingAddress {
    firstName
    lastName
    streetAddress1
    streetAddress2
    companyName
    city
    cityArea
    postalCode
    country {
      code
      country
    }
    countryArea
    phone
  }
  availablePaymentGateways {
    name
    id
    currencies
  }
  totalPrice {
    gross {
      amount
      currency
    }
  }
`;

function saveCartToken(token, req = null, res = null) {
  if (typeof window !== 'undefined') {
    setCookie(COOKIE_NAME, token);
  } else {
    setCookie(COOKIE_NAME, token, { req, res });
  }
}

function getCartToken(req = null, res = null) {
  if (typeof window !== 'undefined') {
    return getCookie(COOKIE_NAME);
  } else {
    return getCookie(COOKIE_NAME, { req, res });
  }
}

function deleteCartToken(req = null, res = null) {
  if (typeof window !== 'undefined') {
    deleteCookie(COOKIE_NAME);
  } else {
    deleteCookie(COOKIE_NAME, { req, res });
  }
}

async function getSubscriptionProduct() {
  try {
    const data = await query({
      query: gql`
        query Products($filter: ProductFilterInput, $first: Int, $channel: String) {
          products(filter: $filter, first: $first, channel: $channel) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          isPublished: true,
          productTypes: [serverRuntimeConfig.SUBSCRIPTION_CAT],
        },
        first: 10,
        channel: serverRuntimeConfig.DEFAULT_CHANNEL_SLUG
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    const product = await query({
      query: gql`
        query Product($id: ID!, $channel: String) {
          product(id: $id, channel: $channel) {
            id
            name
            description
            media {
              url
            }
            category {
              name
            }
            variants {
              id
              name
              pricing {
                price {
                  gross {
                    amount
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        id: data.edges[0].node.id,
        channel: serverRuntimeConfig.DEFAULT_CHANNEL_SLUG
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    return product;
  } catch (err) {
    throw err;
  }
}

async function addSubscription(subOffer, req, res) {
  try {
    const token = getCartToken(req, res);
    if (!token) {
      await checkoutCreate(req, res);
    }
    const product = await getSubscriptionProduct();
    const idProduct = product.variants[0].id;

    return await addProductSubscription(idProduct, subOffer, req, res);
  } catch (err) {
    throw err;
  }
}

async function addProductSubscription(id, subOffer, req, res) {
  try {
    let token = getCartToken(req, res);
    if (!token) {
      await checkoutCreate(req, res);
      token = getCartToken(req, res);
    }
    //TODO: throw error if no token

    const optionalMeta = [];
    if (subOffer.abo) {
      optionalMeta.push({ key: 'ABO_NUM', value: subOffer.abo });
    }
    if (subOffer.recipient) {
      optionalMeta.push({ key: 'RECIPIENT_ID', value: subOffer.recipient });
    }
    if (subOffer.recipientAdr) {
      optionalMeta.push({ key: 'RECIPIENT_ADR', value: JSON.stringify(subOffer.recipientAdr) });
    }

    const data = await mutation({
      mutation: gql`
        mutation CheckoutLinesAdd($id: ID, $lines: [CheckoutLineInput!]!) {
          checkoutLinesAdd(id: $id, lines: $lines) {
            checkout {
              id
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
        lines: [
          {
            quantity: subOffer.copies,
            variantId: id,
            price: subOffer.offer.TARIF_MONTANT,
            forceNewLine: true,
            metadata: [
              { key: 'TYPE', value: subOffer.offer.TYPE_OFFER },
              { key: 'ACTION', value: subOffer.action },
              { key: 'LIB', value: `${subOffer.offer.PUBLICATION} - ${subOffer.offer.DURATION}` },
              { key: 'TARIF_NUM', value: subOffer.offer.TARIF_NUM },
              { key: 'CURRENCY', value: subOffer.offer.CURRENCY },
              ...optionalMeta,
            ],
          },
        ],
      },
      context: {
        app: true,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function addProductIssue(id, req, res) {
  try {
    let token = getCartToken(req, res);
    if (!token) {
      await checkoutCreate(req, res);
      token = getCartToken(req, res);
    }
    //TODO: throw error if no token
    const product = await query({
      query: gql`
        query Product($id: ID!, $channel: String) {
          product(id: $id, channel: $channel) {
            id
            productType {
              metadata {
                key
                value
              }
            }
            variants {
              id
              pricing {
                price {
                  currency
                  gross {
                    currency
                    amount
                  }
                }
              }
            }
            attributes {
              attribute {
                name
                slug
              }
              values {
                name
              }
            }
          }
        }
      `,
      variables: { 
        id: id,
        channel: serverRuntimeConfig.DEFAULT_CHANNEL_SLUG
      },
      // context: { app: true },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    if (!product || !product.variants[0].id) {
      throw publicRuntimeConfig.ERROR_500;
    }
    const variantId = product.variants[0].id;
    const price = product.variants[0].pricing.price.gross;
    const issue = product.attributes.find((x) => x.attribute.slug === 'issue')?.values[0]?.name;
    const publiNum = product.productType.metadata.find((x) => x.key === "PUBLI_NUM")?.value;

    const checkout = await query({
      query: gql`
        query Checkout($id: ID!) {
          checkout(id: $id) {
            id
            lines {
              variant {
                id
              }
            }
          }
        }
      `,
      variables: { id: token },
      fetchPolicy: 'no-cache',
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    const alreadyInCart = checkout.lines.find(line => line.variant.id === variantId);
    if (alreadyInCart && alreadyInCart?.variant?.id) {
      throw new Error("limit-product")
    }

    const data = await mutation({
      mutation: gql`
        mutation CheckoutLinesAdd($id: ID, $lines: [CheckoutLineInput!]!) {
          checkoutLinesAdd(id: $id, lines: $lines) {
            checkout {
              id
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
        lines: [
          {
            quantity: 1,
            variantId: variantId,
            price: price.amout,
            // forceNewLine: true,
            metadata: [
              { key: 'LIB', value: `N°${issue} - Animation & Éducation (version numérique)` },
              { key: 'CURRENCY', value: '€' },
              { key: "NUMERO", value: issue },
              { key: "PUBLI_NUM", value: publiNum }
            ],
          },
        ],
      },
      context: { app: true },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function addProduct(id, copies, price, req, res) {
  try {
    let token = getCartToken(req, res);
    if (!token) {
      await checkoutCreate(req, res);
      token = getCartToken(req, res);
    }
    //TODO: throw error if no token

    const data = await mutation({
      mutation: gql`
        mutation CheckoutLinesAdd($id: ID, $lines: [CheckoutLineInput!]!) {
          checkoutLinesAdd(id: $id, lines: $lines) {
            checkout {
              id
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
        lines: price
          ? [
            {
              quantity: copies,
              variantId: id,
              price: price,
            },
          ]
          : [
            {
              quantity: copies,
              variantId: id,
            },
          ],
      },
      context: {
        app: price ? true : false,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function addMultipleProduct(id, products) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation CheckoutLinesAdd($id: ID, $lines: [CheckoutLineInput!]!) {
          checkoutLinesAdd(id: $id, lines: $lines) {
            checkout {
              id
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: id,
        lines: products,
      },
      context: {
        app: false,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function checkoutCreate(req, res) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation CheckoutCreate($input: CheckoutCreateInput!) {
          checkoutCreate(input: $input) {
            checkout {
              ${CHECKOUT_QUERY}
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        input: {
          channel: serverRuntimeConfig.DEFAULT_CHANNEL_SLUG,
          lines: [],
        },
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    return attachToUser(data.checkout, req, res);
  } catch (err) {
    throw err;
  }
}

async function checkout(req, res) {
  try {
    const token = getCartToken(req, res);
    if (!token) {
      return checkoutCreate(req, res);
    }
    const checkout = await query({
      query: gql`
        query Checkout($id: ID) {
          checkout(id: $id) {
            ${CHECKOUT_QUERY}
          }
        }
      `,
      variables: {
        id: token,
      },
      throwError: true,
      fetchPolicy: 'no-cache',
    }).catch((err) => {
      throw err;
    });
    if (token && !checkout) {
      deleteCartToken(req, res);
      console.log('TOKEN BUT NO CHECKOUT, RECHECKOUT');
      return await this.checkout(req, res);
    }
    const userCheckout = await attachToUser(checkout, req, res);
    if (token && userCheckout && userCheckout.lines.length > 0) {
      // Remove already owned issue numbers
      const indivNum = userCheckout?.user?.metadata?.find((metadata) => metadata.key == 'INDIV_NUM')?.value;
      if (indivNum) {
        const rights = await individuPresseService.getIndividuRight(indivNum)
        userCheckout.lines.forEach((line, index, obj) => {
          const issue = line.metadata.find(data => data.key === "NUMERO")?.value
          if (rights.includes(issue)) {
            checkoutLinesDelete(line.id, req, res)
            // obj.splice(index, 1)
          }
        });
      }
    }
    return userCheckout;
  } catch (err) {
    throw err;
  }
}

async function attachToUser(checkout, req, res) {
  try {
    const me = await customerSaleor.me(true);
    if (checkout && !checkout.user && me) {
      // if (me) {
      const cartLogged =
        me.checkouts.edges && me.checkouts.edges?.[0] && me.checkouts.edges[0].node
          ? me.checkouts.edges[0].node
          : null;

      if (cartLogged && cartLogged.lines.length >= 0) {
        if (checkout?.lines?.length > 0) {
          const products = checkout.lines
          .filter(line => {
            const index = cartLogged.lines.findIndex(che => che.variant.id === line.variant.id)
            return index !== -1 ? false : true
          })
          .map((line) => {
            const metadatas = line.metadata.map((meta) => {
              return {
                key: meta.key,
                value: meta.value
              }
            })
            return {
              quantity: line.quantity,
              variantId: line.variant.id,
              forceNewLine: true,
              metadata: metadatas
            };
          });
          if (products && Array.isArray(products) && products.length > 0)
            await cartSaleor.addMultipleProduct(cartLogged.id, products);
        }

        cartSaleor.deleteCartToken(req, res);
        cartSaleor.saveCartToken(cartLogged.id, req, res);

        console.log('take user cart');
        return await query({
          query: gql`
              query Checkout($id: ID) {
                checkout(id: $id) {
                  ${CHECKOUT_QUERY}
                }
              }
            `,
          variables: {
            id: cartLogged.id,
          },
          throwError: true,
        }).catch((err) => {
          throw err;
        });
      } else {
        cartSaleor.deleteCartToken(req, res);
        cartSaleor.saveCartToken(checkout.id, req, res);

        console.log('take new cart');
        return await cartSaleor.checkoutCustomerAttach(req, res, checkout.id);
      }
      // }
    } else if (checkout && checkout.user && !me) {
      cartSaleor.deleteCartToken(req, res);
      console.log('USER LOGGED OUT, RECHECKOUT');
      return await this.checkout(req, res);
    }
    if (!getCartToken(req, res)) {
      cartSaleor.saveCartToken(checkout.id, req, res);
    }
    return checkout;
  } catch (err) {
    throw err;
  }
}

async function checkoutLinesDelete(id, req, res) {
  try {
    let token = getCartToken(req, res);
    if (!token) {
      //TODO: throw error if no token
      return {};
    }

    const data = await mutation({
      mutation: gql`
        mutation CheckoutLinesDelete($id: ID, $linesIds: [ID!]!) {
          checkoutLinesDelete(id: $id, linesIds: $linesIds) {
            checkout {
              ${CHECKOUT_QUERY}
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
        linesIds: [id],
      },
      context: {
        app: true,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function checkoutCustomerAttach(req, res, checkoutId = null) {
  try {
    const token = checkoutId ? checkoutId : getCartToken(req, res);
    if (!token) {
      //TODO: throw error if no token
      return {};
    }

    const me = await customerSaleor.me();
    if (!me || !me.id) {
      //TODO: throw error if no token
      return {};
    }

    await mutation({
      mutation: gql`
        mutation CheckoutCustomerAttach($id: ID, $customerId: ID) {
          checkoutCustomerAttach(id: $id, customerId: $customerId) {
            checkout {
              id
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
        customerId: me.id,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    // let shippingAdrCart = {
    //   ...me.defaultShippingAddress,
    //   country: me.defaultShippingAddress.country.code
    // };
    // let {__typename: __typename1, ...restAdrShip} = shippingAdrCart

    let billingAdrCart = {
      ...me.defaultBillingAddress,
      country: me.defaultBillingAddress.country.code,
    };
    let { __typename: __typename2, ...restAdrBill } = billingAdrCart;

    // await mutation({
    //   mutation: gql`
    //     mutation CheckoutShippingAddressUpdate($id: ID, $shippingAddress: AddressInput!) {
    //       checkoutShippingAddressUpdate(id: $id, shippingAddress: $shippingAddress) {
    //         checkout {
    //           ${CHECKOUT_QUERY}
    //         }
    //         errors {
    //           field
    //           message
    //         }
    //       }
    //     }
    //   `,
    //   variables: {
    //     id: token,
    //     shippingAddress: restAdrShip
    //   },
    //   throwError: true,
    // }).catch((err) => {
    //   throw err;
    // });

    const data = await mutation({
      mutation: gql`
        mutation CheckoutBillingAddressUpdate($id: ID, $billingAddress: AddressInput!) {
          checkoutBillingAddressUpdate(id: $id, billingAddress: $billingAddress) {
            checkout {
              ${CHECKOUT_QUERY}
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
        billingAddress: restAdrBill,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    if (data && data.checkout) {
      return data.checkout;
    } else {
      //TODO: Erreur si pas de checkout récupérer
      throw new Error('Pas de checkout');
    }
  } catch (err) {
    throw err;
  }
}

async function checkoutBillingAddressUpdate(req, res) {
  try {
    const token = getCartToken(req, res);
    if (!token) {
      //TODO: throw error if no token
      return {};
    }

    const me = await customerSaleor.me();
    if (!me || !me.id) {
      //TODO: throw error if no token
      return {};
    }

    let billingAdrCart = {
      ...me.defaultBillingAddress,
      country: me.defaultBillingAddress.country.code,
    };
    let { __typename: __typename2, ...restAdrBill } = billingAdrCart;

    const data = await mutation({
      mutation: gql`
        mutation CheckoutBillingAddressUpdate($id: ID, $billingAddress: AddressInput!) {
          checkoutBillingAddressUpdate(id: $id, billingAddress: $billingAddress) {
            checkout {
              ${CHECKOUT_QUERY}
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
        billingAddress: restAdrBill,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    if (data && data.checkout) {
      return data.checkout;
    } else {
      //TODO: Erreur si pas de checkout récupérer
      throw new Error('Pas de checkout');
    }
  } catch (err) {
    throw err;
  }
}

async function checkoutPaymentCreate(gateway, req, res) {
  try {
    const token = getCartToken(req, res);
    if (!token) {
      //TODO: throw error if no token
      console.log('No Token');
      return {};
    }

    const data = await mutation({
      mutation: gql`
        mutation CheckoutPaymentCreate($id: ID, $input: PaymentInput!) {
          checkoutPaymentCreate(id: $id, input: $input) {
            payment {
              id
              chargeStatus
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
        input: {
          gateway: gateway,
          token: gateway === 'mirumee.payments.dummy_credit_card' ? '4000000000000069' : '',
        },
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}


async function checkoutComplete(req, res) {
  try {
    const token = getCartToken(req, res);
    if (!token) {
      //TODO: throw error if no token
      return {};
    }

    const data = await mutation({
      mutation: gql`
        mutation CheckoutComplete($id: ID) {
          checkoutComplete(id: $id) {
            order {
              id
            }
            confirmationData
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: token,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function getCheckout(orderId, req, res) {
  try {
    const data = await query({
      query: gql`
        query Order($id: ID) {
          order(id: $id) {
            id
            number
            total {
              net {
                amount
              }
            }
            transactions {
              id
              reference
            }
            lines {
              id
              quantity
              unitPrice {
                gross {
                  amount
                  currency
                }
              }
              metadata {
                key
                value
              }
              totalPrice {
                gross {
                  amount
                  currency
                }
              }
              variant {
                id
                product {
                  id
                  name
                  slug
                  thumbnail {
                    url
                    alt
                  }
                }
                name
              }
            }
            user {
              metadata {
                value
                key
              }
            }
          }
        }
      `,
      variables: {
        id: orderId,
      },
      context: { app: true },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

export const cartSaleor = {
  getCartToken,
  addSubscription,
  checkoutCreate,
  checkout,
  checkoutLinesDelete,
  checkoutCustomerAttach,
  checkoutPaymentCreate,
  checkoutComplete,
  deleteCartToken,
  saveCartToken,
  addMultipleProduct,
  addProductIssue,
  checkoutBillingAddressUpdate,
  getCheckout,
};
