import { getOperationName } from '@apollo/client/utilities';
import client, { setGraphQlToken, setGraphQlRefresh } from '../../apollo-client';

// export const init = (token = null, refresh = null) => {
//   if (token) setGraphQlToken(token)
//   if (refresh) setGraphQlRefresh(refresh)
// };

export const query = async (param, app) => {
  const opName = getOperationName(param.query);
  const name = opName.charAt(0).toLowerCase() + opName.slice(1);
  const {
    data: { [name]: data },
  } = await client.query(param).catch((err) => {
    throw err;
  });

  if (param.throwError)
    if (data?.errors && Array.isArray(data.errors) && data.errors.length > 0) throw data.errors;

  return data;
};

export const mutation = async (param, app) => {
  const opName = getOperationName(param.mutation);
  const name = opName.charAt(0).toLowerCase() + opName.slice(1);
  const {
    data: { [name]: data },
  } = await client.mutate(param).catch((err) => {
    throw err;
  });
  if (param.throwError)
    if (data?.errors && Array.isArray(data.errors) && data.errors.length > 0) throw data.errors;

  return data;
};
