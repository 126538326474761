import useSWR from 'swr';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './CarrouselIssue.module.scss';

import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import Image from 'next/image';
import Link from 'next/link';

import { getIssueNumber } from '../../utils'

const SampleNextArrow = ({ onClick }) => (
  <HiOutlineChevronRight 
    onClick={onClick}
    className={`
      text-green-dark font-bold text-8xl absolute top-1/2 left-full lg:left-[105%] -translate-y-1/2 -translate-x-1/2
      hover:text-green z-30 cursor-pointer
    `}
  />
)

const SamplePrevArrow = ({ onClick }) => (
  <HiOutlineChevronLeft 
    onClick={onClick}
    className={`
      text-green-dark font-bold text-8xl absolute top-1/2 lg:-left-[5%] -translate-y-1/2 -translate-x-1/2
      hover:text-green z-30 cursor-pointer
    `}
  />
)

export function CarrouselIssue({ issues }) {
  const waitingTime = 4000

  const settings = {
    variableWidth: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: waitingTime,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };

  return (
    <Slider {...settings} className={"h-full " + styles['issue-slider']}>
      {issues?.edges?.map(({ node }, key) => (
        <Link key={key} href={`/librairie/${getIssueNumber(node)}`}>
          <Image
            className="shadow-[0_-4px_23px_5px_rgba(0,0,0,0.4)] rounded-2xl"
            src={node.thumbnail.url}
            alt={node.thumbnail.alt}
            width={1200}
            height={400}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Link>
      ))}
    </Slider>
  );
}
