/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api`;

async function search(lastname, indivnum, token) {
  if (typeof window == 'undefined') return;
  return await axiosInstance.axiosGetFetcher(`${baseUrl}/customer/search?lastname=${lastname}&indivnum=${indivnum}&token=${token}`);
}


async function update(indivnum, values) {
  if (typeof window == 'undefined') return;
  return await axiosInstance.axiosPutFetcher(`${baseUrl}/customer/${indivnum}`, values);
}

async function updateAddress(indivnum, values) {
  if (typeof window == 'undefined') return;
  return await axiosInstance.axiosPutFetcher(`${baseUrl}/customer/${indivnum}/address`, values);
}

async function updateNewsletter(indivnum, values) {
  if (typeof window == 'undefined') return;
  return await axiosInstance.axiosPutFetcher(`${baseUrl}/customer/${indivnum}/newsletter`, values);
}

export const customerService = {
  update, updateAddress, updateNewsletter, search
};
