/* OTHER LIB IMPORT */
import { setCookie, getCookie, deleteCookie } from 'cookies-next';

/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';
import { cartSaleor } from './saleor';


/* Constante */
const baseUrl = `/api`;

const TOKEN_COOKIE_NAME = 'token';
const REFRESH_COOKIE_NAME = 'refresh';

async function login(login, password, token) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/login`, {
    email: login,
    password: password,
    token
  });
}

async function register(values) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/register`, values);
}

async function lostPassword(email, token) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/customer/password/lost`, { email, token });
}

async function recoveryPassword(email, password, confirmPassword, token, gToken) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/customer/password/recovery`, { email, password, confirmPassword, token, gToken });
}

async function callLogout() {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/logout`);
}


const logout = (req, res) => {
  cartSaleor.deleteCartToken(req, res)
  deleteToken(req, res);
  deleteRefresh(req, res);
}

/***********************/
/* Manage Token Cookie */
/***********************/
const setToken = (token, req = null, res = null) => {
  if (typeof window !== 'undefined') {
    setCookie(TOKEN_COOKIE_NAME, token);
  } else {
    setCookie(TOKEN_COOKIE_NAME, token, { req, res });
  }
}

const getToken = (req = null, res = null) => {
  if (typeof window !== 'undefined') {
    return getCookie(TOKEN_COOKIE_NAME);
  } else {
    return getCookie(TOKEN_COOKIE_NAME, { req, res });
  }
}

const deleteToken = (req = null, res = null) => {
  if (typeof window !== 'undefined') {
    deleteCookie(TOKEN_COOKIE_NAME);
  } else {
    deleteCookie(TOKEN_COOKIE_NAME, { req, res });
  }
}

/***********************/
/* Manage Refresh Cookie */
/***********************/
const setRefresh = (token, req = null, res = null) => {
  if (typeof window !== 'undefined') {
    setCookie(REFRESH_COOKIE_NAME, token);
  } else {
    setCookie(REFRESH_COOKIE_NAME, token, { req, res });
  }
}

const getRefresh = (req = null, res = null) => {
  if (typeof window !== 'undefined') {
    return getCookie(REFRESH_COOKIE_NAME);
  } else {
    return getCookie(REFRESH_COOKIE_NAME, { req, res });
  }
}

const deleteRefresh = (req = null, res = null) => {
  if (typeof window !== 'undefined') {
    deleteCookie(REFRESH_COOKIE_NAME);
  } else {
    deleteCookie(REFRESH_COOKIE_NAME, { req, res });
  }
}

export const authService = {
  login,
  register,
  lostPassword,
  recoveryPassword,
  logout,
  setToken,
  getToken,
  setRefresh,
  getRefresh,
  callLogout
};
