import { Button } from '../../components';
import { Login } from './Login';
import { AskRegister } from './AskRegister';

export function Authentication({ redirectNoChange = false }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-16">
      <div className="col-span-2">
        <h2 className="text-3xl mb-5">Je suis déjà abonné.e à <span className='whitespace-nowrap'>Animation & Éducation</span></h2>
        <Login redirectNoChange={redirectNoChange} />
      </div>
      <div className="mx-auto h-0.5 md:h-full w-full md:w-0.5 bg-orange relative  my-16 md:my-0 ">
        <span className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 md:left-0 md:top-1/2 text-3xl bg-white border-2 border-orange rounded-full p-5">OU</span>
      </div>
      <div className="col-span-2 flex justify-center items-center">
        <AskRegister />
      </div>
    </div>
  );
}
