import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Form, Input, Select, DatePicker } from '../form';

import { Field } from 'react-final-form'
import {
  composeValidators, maxLength, regex, required, selectState
} from './../../utils';
import { validationService, customerService } from '../../../lib/services';
import { useAppContext } from '../../../lib/context';
import { Button } from '../Button';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export function EditAddress({ initialValues, close }) {
  const { addAlert, getForm } = useAppContext();
  const [values, setValues] = useState(false);
  const [rules, setRules] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const router = useRouter();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    getForm("edit-address").subscribe(({ values }) => {
      setValues(values)
    }, { values: true })
    validationRules(values.country ?? initialValues.country)
  }, [])

  useEffect(() => {
    getForm("edit-address")?.mutators?.setValue("postalCode", values.postalCode ?? initialValues.postalCode)
  }, [rules])

  const validationRules = async (country = null) => {
    try {
      const data = await validationService.getAddressRules(country ?? values.country ?? "FR");
      setRules(data)
    } catch (err) {
      setRules({})
    }
  }

  const submit = async (values) => {
    setIsProcessing(true)
    try {
      if (!executeRecaptcha) {
        addAlert({
          status: "error",
          msg: publicRuntimeConfig.ERROR_500
        })
        setIsProcessing(false);
        return;
      };
      const token = await executeRecaptcha();
      if (!token) {
        addAlert({
          status: "error",
          msg: publicRuntimeConfig.ERROR_500
        })
        setIsProcessing(false);
        return;
      }
      values.token = token;
      const data = await customerService.updateAddress(values.indivnum, values);
      if (data) addAlert(data)
      close()
      // authService.reloadUser();
      router.replace(router.asPath);
    } catch (err) {
      addAlert({
        status: err?.response?.data?.status !== 200 ? "error" : "success",
        msg: err?.response?.data?.msg
      })
    }
    setIsProcessing(false)
  }

  return (
    <Form
      formName="edit-address"
      onSubmit={submit}
      initialValues={initialValues}
    >
      <div className="grid grid-cols-6 gap-4">
        <Field
          classContainer="col-span-6 sm:col-span-1"
          component={Select}
          label="Civilité"
          name="civ"
          list={["civ"]}
        />
        <Field
          classContainer="col-span-6 sm:col-span-2"
          component={Input}
          validate={composeValidators(required, maxLength(30))}
          asterisk={true}
          label="Prénom"
          name="firstname"
          type="text"
        />
        <Field
          classContainer="col-span-6 sm:col-span-2"
          component={Input}
          validate={composeValidators(required, maxLength(30))}
          label="Nom"
          asterisk={true}
          name="lastname"
          type="text"
        />
        <Field
          classContainer="col-span-6 sm:col-span-2"
          component={DatePicker}
          // validate={date}
          label="Date de naissance"
          name="birthday"
          maxDate="today"
        />
        <span className="col-span-4 hidden sm:block"></span>
        <Field
          classContainer="col-span-6 sm:col-span-2 mt-5"
          component={Input}
          validate={maxLength(20)}
          label="Téléphone"
          type="phone"
          name="phone"
        />
        <span className="col-span-4 hidden sm:block"></span>
        <Field
          classContainer="col-span-6 sm:col-span-2"
          component={Input}
          validate={maxLength(30)}
          label="Société"
          type="text"
          name="company"
        />
        <Field
          classContainer="col-span-6 mt-5"
          component={Input}
          asterisk={true}
          validate={composeValidators(required, maxLength(38))}
          label="Adresse"
          name="streetAddress1"
          type="text"
        />
        <Field
          classContainer="col-span-6"
          component={Input}
          validate={composeValidators(maxLength(38))}
          name="streetAddress2"
          type="text"
        />
        <Field
          classContainer="col-span-6 sm:col-span-2"
          component={Input}
          asterisk={true}
          validate={composeValidators(required, maxLength(15), regex(rules.postalCodeMatchers, rules.postalCodeExamples))}
          label="Code postal"
          name="postalCode"
          type="text"
          onChange={e => selectState(e, getForm("edit-address"), "state")}
        />
        <Field
          classContainer="col-span-6 sm:col-span-2"
          component={Input}
          asterisk={true}
          validate={composeValidators(required, maxLength(38))}
          label="Ville"
          name="city"
          type="text"
        />
        <span className="col-span-2 hidden sm:block"></span>
        <Field
          classContainer="col-span-6 sm:col-span-2"
          component={Select}
          asterisk={true}
          validate={required}
          list={["country"]}
          onChange={(e) => validationRules(e.target.value)}
          // disabled={true}
          label="Pays"
          name="country"
        />
        <Field
          classContainer="col-span-6 sm:col-span-2"
          component={Select}
          asterisk={values?.["state-options"]}
          validate={values?.["state-options"] ? required : null}
          country={values.country ?? initialValues.country}
          hideIfEmpty={true}
          list={["departement"]}
          label="Département"
          type="text"
          name="state"
          onChange={e => {
            if (!values.postalCode.startsWith(e.target.value) && values.country === "FR") {
              getForm("edit-address").mutators.setValue("postalCode", e.target.value)
            }
          }}
        />
        <Button
          link={false}
          disabled={isProcessing}
          isLoading={isProcessing}
          type="submit"
          text="Enregistrer"
          className="px-10 py-3 text-lg h-fit self-end"
        />
      </div>
    </Form>
  );
}
