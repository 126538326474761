/* OUR CODE IMPORT */
import { axiosInstance } from '../../utils';
import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();

const baseUrl = `https://www.google.com/recaptcha/api/siteverify`;

async function isCaptchaValid(token, score = 0.5) {
  const captcha = await axiosInstance.axiosPostFetcher(`${baseUrl}?secret=${serverRuntimeConfig.PRIVATE_CAPTCHA}&response=${token}`);
  return captcha.success && captcha.score >= score;
}

export const captchaService = {
  isCaptchaValid
};
