/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';

const baseUrl = `/api`;
const SUB_COOKIE_NAME = 'subscription-temp'

async function getOffers(country) {
  return await axiosInstance.axiosGetFetcher(`${baseUrl}/subscription/offer/?country=${country}`);
}

async function getOffersForCustomer(indivnum, lastname, token) {
  return await axiosInstance.axiosGetFetcher(`${baseUrl}/subscription/offer/customer/?indivnum=${indivnum}&lastname=${lastname}&token=${token}`);
}

async function create(values) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/subscription/create`, values);
}

async function validation(values) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/subscription/validation`, values);
}

async function recipientValidation(values) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/subscription/recipient`, values);
}

async function addSubToCart(values) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/cart/add/subscription`, values);
}

function saveSubscription(subs, req, res) {
  setCookie(SUB_COOKIE_NAME, subs, { req, res, httpOnly: true });
}

function getSubscription(req, res) {
  const sub = getCookie(SUB_COOKIE_NAME, { req, res });
  return sub ? JSON.parse(sub) : null
}

function deleteSubscription(req, res) {
  deleteCookie(SUB_COOKIE_NAME, { req, res });
}


export const subscriptionService = {
  getOffers, create, validation, saveSubscription, getSubscription, deleteSubscription,
  addSubToCart, getOffersForCustomer, recipientValidation
};
