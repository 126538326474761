import { composeValidators, required } from '../../../utils';

import { Input } from '../../form';
import { Field } from 'react-final-form';

const PAYMENT_TYPE_ID = 'mirumee.payments.dummy_credit_card';

export function Dummy({ onClick, selectedPaymentMethod }) {
  return (
    <>
      <Field
        component={Input}
        key={PAYMENT_TYPE_ID}
        label={'Méthode de Test'}
        type="radio"
        name="gateway"
        value={PAYMENT_TYPE_ID}
        onClick={onClick}
        validate={composeValidators(required)}
      />
    </>
  );
}
