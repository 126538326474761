import { domTomArray } from "./verifyCountry"

export const selectState = (value, form, inputState) => {
  value = value?.target?.value ? value.target.value : value
  if (value && value.length >= 2) {
    const isDomTom = value.length > 2 ? domTomArray().includes(value.substring(0, 3)) : false
    // if we want domtom postal code 987 => 3 number so substring 3
    value = value.substring(0, isDomTom ? 3 : 2)
    // for Corse
    value = value === "2A" || value === "2B" ? 20 : value 
    const valueExist = isValueExist(value, inputState)
    if (valueExist) {
      form?.mutators?.setValue(inputState, value)
    }
  }
}

const isValueExist = (value, inputState) => {
  let valueExist = false

  const el = document.getElementsByName(inputState)
  if (el && el[0]) {
    for (let i = 0; i < el[0].length; ++i){
      if (el[0].options[i].value == value){
        valueExist = true;
      }
    }
  }

  return valueExist;
}