import { AiFillExclamationCircle } from 'react-icons/ai';

export function InputCheckbox({ input, label, onChange, meta, name, classContainer = "", ...rest }) {
    return (
        <div className={"text-base " + classContainer}>
            <div className="flex items-center">
                <input
                    {...input}
                    {...rest}
                    type="checkbox"
                    id={`${input.name}`}
                    onChange={(e) => {
                        input?.onChange(e.target.checked);
                        if (onChange) onChange(e);
                    }}
                    className="w-4 h-4 text-blue-light bg-gray-100 border-gray-300 rounded focus:ring-bleu-light focus:ring-2"
                />
                <label htmlFor={`${input.name}`} className="ml-4 font-semibold">{label}</label>
            </div>
            {meta.touched && meta.error && (
                <span className={'flex items-center mb-3 text-pink font-normal w-full'}>
                    <AiFillExclamationCircle className="mr-1" />
                    {meta.error}
                </span>
            )}
        </div>
    );
}
