/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api`;

async function getCountries(cp = null) {
  return await axiosInstance.axiosGetFetcher(`${baseUrl}/country/${cp && !isNaN(cp) ? `?cp=${cp}` : ''}`);
}

export const countryService = {
  getCountries
};
