import { useState } from 'react';

import { Stripe, Dummy, SystemPay } from './payment';

export function CheckoutPaymentMethod({ cartList }) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const displayPaymentMoreOption = async (event) => {
    let checkboxValue = event.target.value;
    setSelectedPaymentMethod(checkboxValue);
  };

  return (
    <div>
      {cartList &&
        cartList.availablePaymentGateways &&
        cartList?.availablePaymentGateways.map((pay) => (
          <div key={pay.id}>
            {pay.id == 'saleor.payments.stripe' && (
              <Stripe
                key={pay.id}
                onClick={displayPaymentMoreOption}
                selectedPaymentMethod={selectedPaymentMethod}
              />
            )}
            {pay.id == 'mirumee.payments.dummy_credit_card' && (
              <Dummy
                key={pay.id}
                onClick={displayPaymentMoreOption}
                selectedPaymentMethod={selectedPaymentMethod}
              />
            )}
            {pay.id == 'sotiaf.payments.system_pay' && (
              <SystemPay
                key={pay.id}
                onClick={displayPaymentMoreOption}
                isSinglePaymentMethod={cartList?.availablePaymentGateways.length < 2}
                selectedPaymentMethod={selectedPaymentMethod}
              />
            )}
          </div>
        ))}
    </div>
  );
}
