import { Fragment } from "react";
import { AiFillExclamationCircle } from "react-icons/ai"
import { BsAsterisk } from "react-icons/bs"

import { default as DatePickerInput, registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { parse, isValid, isMatch, format } from "date-fns";
import fr from 'date-fns/locale/fr';
import { formatToBack } from "../../utils";
registerLocale('fr', fr)

export function DatePicker({ 
  input, onChange, meta = {}, label = "", classContainer = "", classInput = "",
  asterisk = false, action = null, minDate = null, maxDate = null, ...rest 
}) {
  if (minDate && isMatch(minDate, "dd/MM/yyyy")) minDate = parse(minDate, "dd/MM/yyyy", new Date())
  if (maxDate && isMatch(maxDate, "dd/MM/yyyy")) maxDate = parse(maxDate, "dd/MM/yyyy", new Date())
  if (maxDate && maxDate === "today") maxDate = new Date()

  return (
    <label className={"text-lg font-semibold " + classContainer}>
      {action ?
        <Fragment>
          {label}
          {asterisk && <BsAsterisk className="inline text-xxs align-super text-pink " />}
          {action}
        </Fragment>
      :
        <Fragment>
          {label}
          {asterisk && <BsAsterisk className="inline text-xxs align-super text-pink " />}
        </Fragment>
      }
      <DatePickerInput
        {...input}
        {...rest}
        className={"h-10 bg-gray-50 border border-gray-300 rounded-md block w-full px-2 py-1 font-normal disabled:cursor-no-drop disabled:bg-gray-300 " + classInput}
        dateFormat={"dd/MM/yyyy"}
        selected={formatToBack(input.value)}
        onChange={date => input.onChange(date)}
        minDate={minDate}
        maxDate={maxDate}
        disabledKeyboardNavigation1
        placeholderText={"DD/MM/YYYY"}
        locale="fr"
      />
      {meta.touched && meta.error && 
        <span className="flex items-center mb-3 text-pink font-normal">
          <AiFillExclamationCircle className="mr-1" />{meta.error}
        </span>
      }
    </label>
  );
}
