import { Fragment, useState, useEffect, useRef } from "react";
import { AiFillExclamationCircle } from "react-icons/ai"
import { BsAsterisk } from "react-icons/bs"

import civList from "../../utils/civ.json";
import { countryService, stateService } from "../../services";
import { useForm } from "react-final-form";

export function Select({
  input, meta = {}, list = [], label = "", classContainer = "", classInput = "",
  asterisk = false, action = null, canBeEmpty = false, children, onChange,
  country = null, hideIfEmpty = false, ...rest
}) {
  const [ preList, setPreList ] = useState([]);
  const [ lastCountry, setLastCountry ] = useState(null);
  const mounted = useRef(false);

  const form = input?.onBlur && "render" in rest ? useForm() : null;

  useEffect(() => {
    if (!list.includes("departement") || !mounted.current || !country || country === lastCountry) {
      return;
    }
    (async () => {
      const deps = await loadDepartement();
      const temp = preList && preList.length > 0 ? preList.filter(item => !item?.key?.startsWith('dep')) : [];
      temp.push(...deps);
      setLastCountry(country);
      setPreList(temp)
      if (form && temp && temp.length > 0) {
        form.change(`${input.name}-options`, true)
      } else if (form) {
        form.change(`${input.name}-options`, false)
      }
      if (temp && temp.length > 0 && !input.value) {
        input?.onChange(temp[0].props.value)
      }
    } )();
  }, [country])

  useEffect(() => {
    loadList();
  }, [])
  
  const loadList = async () => {
    mounted.current = true;
    const temp = [];
    await Promise.all(list.map(async el => {
      switch (el) {
        case "civ":
          Object.keys(civList).map((key) => {
            temp.push(<option key={'civ-'+key} value={key}>{civList[key]}</option>)
          })
          break;
        case "departement":
          const deps = await loadDepartement();
          temp.push(...deps);
          break;
        case "country":
          const countries = await countryService.getCountries();
          countries.map((country, key) => {
            temp.push(<option key={'country-'+key} value={country.code}>{country.libinsee}</option>)
          })
          break;
      }
    }))
    setPreList(temp)
    if (form && temp && temp.length > 0) {
      form.change(`${input.name}-options`, true)
    } else if (form) {
      form.change(`${input.name}-options`, false)
    }
  }

  const loadDepartement = async () => {
    try {
      const temp = [];
      const states = await stateService.getStatesByCode(country ?? "FR")
      states.map((deps, key) => {
        if (deps.etatabr)
          temp.push(<option key={`dep-${country ?? "FR"}-${key}`} value={deps.etatabr}>{country === "FR" || !country ? deps.etatabr+" - "+deps.etatlib : deps.etatlib}</option>)
      })
      return temp;
    } catch (err) {
      return [];
    }
  }

  if (hideIfEmpty && ((preList && preList.length === 0) || !preList)) {
    return (<label className={"text-lg font-semibold " + classContainer}></label>);
  }
  return (
    <label className={"text-lg font-semibold " + classContainer}>
      {action ?
        <Fragment>
          {label}
          {asterisk && <BsAsterisk className="inline text-xxs align-super text-pink " />}
          {action}
        </Fragment>
      :
        <Fragment>
          {label}
          {asterisk && <BsAsterisk className="inline text-xxs align-super text-pink " />}
        </Fragment>
      }
      <select 
        {...input}
        {...rest}
        onChange={(e) => {
          //TODO: trim space after validation to let user have space in name or firstname
          input?.onChange(e.target?.value.trimStart()/*?.trim()*/)
          if (onChange) onChange(e);
        }}
        className={
          "h-10 bg-gray-50 border border-gray-300 rounded-md block w-full px-2 py-1 font-normal text-black disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-100 "
          + classInput
        }
      >
        {canBeEmpty && <option value=""></option>}
        {preList && preList.length > 0 && preList.map(el => {return el})}
        {children}
      </select>
      {meta.touched && meta.error && 
        <span className="flex items-center mb-3 text-pink font-normal">
          <AiFillExclamationCircle className="mr-1" />{meta.error}
        </span>
      }
    </label>
  );
}
