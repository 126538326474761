import React, { useEffect, useCallback } from 'react';
import { systempayService } from '../../../services';

import Script from 'next/script';
import Head from 'next/head';
import KRGlue from '@lyracom/embedded-form-glue';
// import PropTypes from 'prop-types';

export function SystemPayModal({ onSubmit, closeAction, indivNum, total, checkoutId }) {
  const [mounted, setMounted] = React.useState(false);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) closeAction();
    },
    [closeAction],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const endpoint = 'https://api.systempay.fr';
  const publicKey = '18350874:testpublickey_F0c2c5WK1rSAuRvWykKzE5oWTAUwKgl8VD1OgwPlKo8ZD';
  let formToken = 'DEMO-TOKEN-TO-BE-REPLACED';

  const getPaymentForm = () => {
    systempayService
      .createPayment(total, 'EUR', indivNum, checkoutId)
      .then((resp) => {
        formToken = resp.formToken;
        return KRGlue.loadLibrary(resp.endPoint, resp.publicKey);
      })
      .then(({ KR }) =>
        KR.setFormConfig({
          formToken: formToken,
          'kr-language': 'fr-FR',
        }),
      )
      .then(({ KR }) =>
        KR.onSubmit((paymentData) => {
          if (paymentData.clientAnswer.orderStatus === 'PAID') {
            onSubmit('sotiaf.payments.system_pay', paymentData);
            closeAction();
          } else console.error(`Payement status : ${paymentData.clientAnswer.orderStatus}`);
          return false;
        }),
      )
      .then(({ KR }) => KR.attachForm('#systemPayForm'))
      .then(({ KR, result }) => KR.showForm(result.formId)); /* show the payment form */
  };

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      getPaymentForm();
    }
  }, [mounted, setMounted, getPaymentForm]);

  return (
    <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
      <Head>
        <link
          rel="stylesheet"
          href={`${endpoint}/static/js/krypton-client/V4.0/ext/classic-reset.css`}
        />
      </Head>
      <Script src={`${endpoint}/static/js/krypton-client/V4.0/ext/classic.js`}></Script>
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-75"
        onClick={() => {
          closeAction();
        }}
      ></div>
      <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto w-11/12 md:max-w-md">
        <div
          className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
          onClick={() => {
            closeAction();
          }}
        >
          <i className="fas fa-times text-white"></i>
          <span className="text-sm">(Esc)</span>
        </div>
        <div className="flex justify-between items-center pt-2 px-8">
          <p className="text-2xl font-bold ">Paiement</p>
          <div
            className="modal-close cursor-pointer z-50"
            onClick={() => {
              closeAction();
            }}
          >
            <i className="fas fa-times text-black"></i>
          </div>
        </div>
        <div className="modal-content text-left p-8 pt-4 bg-white">
          <div className="rounded">
            <div>
              {false ? (
                <div className="flex justify-center items-center w-full">
                  <Loader />
                </div>
              ) : (
                <div>
                  <div id="systemPayForm" className="flex justify-center items-center"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
