import React, { Fragment, useEffect, useState } from 'react';
import { v4 } from 'uuid';

export function ToolTip({
  content, position, children
}) {
  if (React.isValidElement(children) && content) {
    const [popup, setPopup] = useState(false)
    const [pos, setPosition] = useState("top-1/2 left-full -translate-y-1/2")
    const [triangle, setTriangle] = useState(`
      top-1/2 right-full -translate-y-1/2 
      border-t-10 border-t-transparent 
      border-b-10 border-b-transparent 
      border-r-10 border-r-blue-darker
    `)

    useEffect(() => {
      switch (position) {
        case "right":
          setPosition("top-1/2 right-full -translate-y-1/2")
          setTriangle(`
            top-1/2 left-full -translate-y-1/2 
            border-t-10 border-t-transparent 
            border-b-10 border-b-transparent 
            border-l-10 border-l-blue-darker
          `)
          break;
        case "top":
          setPosition("bottom-full left-1/2 -translate-x-1/2")
          setTriangle(`
            top-full left-1/2 -translate-x-1/2
            border-l-10 border-l-transparent 
            border-r-10 border-r-transparent 
            border-t-10 border-t-blue-darker
          `)
          break;
        case "bottom":
          setPosition("top-full left-1/2 -translate-x-1/2")
          setTriangle(`
            bottom-full right-1/2 -translate-x-1/2 
            border-l-10 border-l-transparent 
            border-r-10 border-r-transparent 
            border-b-10 border-b-blue-darker
          `)
          break;
      }

    }, [])

    const Popup = () => popup ? (
      <div className={
        `absolute ${pos} bg-gray-200 border 
        border-blue-darker rounded px-3 py-2 w-64 transition-opacity `
        + (popup ? "" : "opacity-0")
      }>
        {content}
        <div className={`absolute w-0 h-0 ${triangle}`}
        ></div>
      </div>
    ) : null

    const newChildren = React.cloneElement(children, {
      className: children.props.className+" relative",
      children: [...(children?.props?.children ?? []), <Popup key={v4()} />],
      onMouseEnter: () => setPopup(true),
      onMouseLeave: () => setPopup(false)
    })

    return (
      <Fragment>
        {newChildren}
      </Fragment>
    )
  }
  return children
}
