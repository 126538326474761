import { useEffect, Children, isValidElement, cloneElement, useState, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form'

import { useAppContext } from '../../../lib/context';

export function Form({ formName, formClass = "", children, ...rest }) {
  const { addForm, removeForm } = useAppContext();
  const formRef = useRef(null);

  useEffect(() => {
    if (formName && formRef.current) addForm(formName, formRef.current)
    return () => {
      if (formName && formRef.current) removeForm(formName)
    }
  }, []);

  return (
    <FinalForm
      {...rest}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        }
      }}
      render={({ handleSubmit, form, values }) => {
        formRef.current = form;

        return (
          <form className={formClass} onSubmit={handleSubmit}>
            {children}
          </form>
        )
      }}
    />
  );
}
