import { useContext, useState, createContext } from 'react';
import { v4 } from 'uuid';

// create context
const AppContext = createContext(null);

const createAppState = () => {
  return {
    //hooks: setupHooks({ erg1, arg2 }),
  };
};

// context provider
const AppProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [loggedSaleor, setLoggedSaleor] = useState(null);
  const [setting, setSetting] = useState({});

  const [cartNb, setCartNb] = useState(0);
  
  const [alerts, setAlerts] = useState([]);
  const addAlert = (alert) => {
    const key = v4();
    if (alert && Array.isArray(alert)) {
      alert.forEach(al => {
        addAlert(al);
      });
    } else if (alert && (alert.status || alert.http) && alert.msg) {
      setAlerts(prev => [...prev, {
        status: alert.status || alert.http,
        msg: alert.msg,
        time: 5,
        key
      }])
    }
  }

  const [forms, setForms] = useState({});
  const addForm = (form, values) => {
    if (form) {
      forms[form] = values
      setForms(forms)
    }
  }
  const removeForm = (form) => {
    if (form && forms[form]) {
      delete forms[form]
      setForms(forms)
    }
  }
  const getForm = (form) => {
    if (form && forms[form]) {
      return forms[form]
    }
  }

  return (
    <AppContext.Provider value={{ 
      auth, setAuth, setting, setSetting, alerts, setAlerts, addAlert,
      loggedSaleor, setLoggedSaleor, addForm, removeForm, getForm, setCartNb, cartNb
    }}>
      {children}
    </AppContext.Provider>
  );
};

function useAppContext() {
  return useContext(AppContext);
}

export { AppContext, AppProvider, useAppContext };
