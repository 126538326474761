import Image from 'next/image';
import { Field } from 'react-final-form'
import { Button } from '../Button';
import { Form, Input } from '../form';
import { composeValidators, required, number } from './../../utils/formValidation';

export function SearchByIndivNum({submit, searchByAdr = null, form = true, prefix = "", imgFilm = false, loading = false}) {
  if (form) {
    return (
      <Form formName="searchByIndivNum" formClass="grid grid-cols-1 gap-4" onSubmit={submit}>
        <Field
          component={Input}
          asterisk={true}
          validate={composeValidators(required)}
          label="Nom"
          type="text"
          name={`${prefix}lastname`}
        />
        <Field
          component={Input}
          asterisk={true}
          validate={composeValidators(required, number)}
          label="N° d'abonné"
          action={searchByAdr ?
            <span 
              className="ml-4 text-sm font-normal underline underline-offset-4 cursor-pointer"
              onClick={() => searchByAdr(true)}
            >
                N° d'abonné oublié ?
            </span> : null}
          type="text"
          name={`${prefix}indivnum`}
        />
        {imgFilm &&
          <Image
            src="/imgs/Film_AboNum.png"
            alt="Image du film plastique avec le numéro d'abonné"
            width={500}
            height={115}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        }
        <Button
          link={false}
          plain={false}
          disabled={loading}
          loading={loading}
          type="submit"
          text="Rechercher"
          className="px-10 py-3 text-lg"
        />
      </Form>
    );
  } else {
    return (
      <div className="grid grid grid-cols-1 sm:grid-cols-3 gap-4">
        <Field
          component={Input}
          asterisk={true}
          label="Nom"
          type="text"
          name={`${prefix}lastname`}
        />
        <Field
          component={Input}
          asterisk={true}
          label="N° d'abonné"
          action={searchByAdr ?
            <span 
              className="ml-4 text-sm font-normal underline underline-offset-4 cursor-pointer"
              onClick={() => searchByAdr(true)}
            >
                N° d'abonné oublié ?
            </span> : null}
          type="text"
          name={`${prefix}indivnum`}
        />
        <Button
          link={false}
          action={submit}
          plain={false}
          disabled={loading}
          loading={loading}
          type="button"
          text="Rechercher"
          className="px-7 py-2 text-lg h-fit self-end"
        />
      </div>
    );
  }
}