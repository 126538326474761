import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
const baseUrl = `${serverRuntimeConfig.API_PRESSE_URL}/abonnement`;

import { axiosInstance } from '../../utils';

const getOffers = async (country) => {
  try {
    return await axiosInstance.axiosGetFetcher(`${baseUrl}/offer?country=${country}`);
  } catch (err) {
    throw err;
  }
};

const getOffersForCustomer = async (indivnum, lastname, publinum) => {
  try {
    return await axiosInstance.axiosGetFetcher(`${baseUrl}/offer/customer?indivnum=${indivnum}&lastname=${lastname}&publinum=${publinum}`);
  } catch (err) {
    throw err;
  }
};

export const abonnementPresseService = { getOffers, getOffersForCustomer };
