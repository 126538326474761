/* NEXT IMPORT */
import getConfig from 'next/config';

/* OTHER LIB IMPORT */
import { setCookie, getCookie, deleteCookie } from 'cookies-next';

/* OUR CODE IMPORT */
import { axiosInstance } from '../../utils';

const { publicRuntimeConfig } = getConfig();
const baseUrl = `${publicRuntimeConfig.API_MAGAZINE_URL}/auth`;

/* Constante */
const TOKEN_PRESSE_COOKIE_NAME = 'tokenPresseBO';

async function login(req, res) {
  if (typeof window !== 'undefined') return;
  const data = await axiosInstance.axiosGetFetcher(baseUrl).catch((err) => {
    throw err;
  });
  setToken(data.token, req, res);
  /* TODO: Gestion des erreurs */
  let error = false;
  if (error) return Promise.reject(Error('Erreur de connexion'));
  /* ------------------- */

  return Promise.resolve();
}

/***********************/
/* Manage Token Cookie */
/***********************/
const setToken = (token, req = null, res = null) => {
  if (typeof window !== 'undefined') {
    setCookie(TOKEN_PRESSE_COOKIE_NAME, token);
  } else {
    setCookie(TOKEN_PRESSE_COOKIE_NAME, token, { req, res });
  }
};

const getToken = (req = null, res = null) => {
  if (typeof window !== 'undefined') {
    return getCookie(TOKEN_PRESSE_COOKIE_NAME);
  } else {
    return getCookie(TOKEN_PRESSE_COOKIE_NAME, { req, res });
  }
};

const deleteToken = (req = null, res = null) => {
  if (typeof window !== 'undefined') {
    deleteCookie(TOKEN_PRESSE_COOKIE_NAME);
  } else {
    deleteCookie(TOKEN_PRESSE_COOKIE_NAME, { req, res });
  }
};

export const authMagazineService = { login, getToken };
