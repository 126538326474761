/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api`;
async function removeFromCart(id) {
  return await axiosInstance.axiosDeleteFetcher(`${baseUrl}/cart/remove/${id}`);
}

export const cartService = {
  removeFromCart
};
