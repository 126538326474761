import { useEffect, useState, useRef } from 'react';

import { TopNav, Alert } from '../index';
import { useAppContext } from '../../../lib/context';

export function Layout({ isLogged, cart }) {
  const header = useRef(null);
  const [sticky, isSticky] = useState(false);
  const [top, setTop] = useState(null);

  const { alerts } = useAppContext();

  useEffect(() => {
    window.addEventListener('scroll', setSticky);
    return () => {
      window.removeEventListener('scroll', setSticky);
    };
  }, []);

  const setSticky = (e) => {
    if (header && header.current) {
      if (window.scrollY >= header.current.clientHeight - 1.25 * 16) {
        setTop(1.25 * 16 + 'px');
        isSticky(true);
      } else if (window.scrollY < header.current.clientHeight - 1.25 * 16) {
        setTop(null);
        isSticky(false);
      }
    }
  };

  return (
    <div ref={header} className="relative flex flex-col">
      <TopNav isLogged={isLogged} cart={cart} />

      {alerts && Array.isArray(alerts) && alerts.length > 0 && (
        <>
          {alerts &&
            alerts?.map((alert, index) => (
              <Alert alert={alert} key={alert.key} index={index} />
            ))}
        </>
        // <div
        //   ref={alertsContainer}
        //   className={
        //     'z-50 top-full left-1/2 -translate-x-1/2 sm:w-auto w-full px-2 ' +
        //     (sticky ? `fixed` : 'absolute')
        //   }
        //   style={sticky && top ? { top: top } : {}}
        // >

        // </div>
      )
      }
    </div >
  );
}
