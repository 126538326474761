/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api/validation`;

async function getAddressRules(country) {
  return await axiosInstance.axiosGetFetcher(`${baseUrl}/address/${country}`);
}

export const validationService = {
  getAddressRules
};
