import { 
  abonnementPresseService, cartSaleor, customerSaleor, individuPresseService,
  statePresseService, subscriptionService 
} from "../services";

import getConfig from 'next/config';
import { userCountryOrDomTom } from ".";
const { publicRuntimeConfig } = getConfig();

export const subscriptionValid = async (req, res, task = 1) => {
  const sub = task === 1 ? req.body : subscriptionService.getSubscription(req, res);
  if (!sub || !sub.offer) {
    res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
    return res
  }

  const { action, country, tarif, offer, recipient, abo } = sub;
  let { copies } = sub;
  if (!action || !country || !tarif || !copies || !offer) {
    res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
    return res
  }

  // No digital for gift action
  if (action === "gift" && offer.TYPE_OFFER === "digital") {
    res.status(400).json({status: 400, msg: "L'offre sélectionnée n'est pas disponible en cadeau, merci de sélectionner une autre offre."})
    return res
  }

  // If someone edit the cookie, we make sure if is always a specific value
  if (!["sub_renew", "gift"].includes(action) || !["full", "digital"].includes(offer.TYPE_OFFER)) {
    res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
    return res
  }

  // If the offer is not the same as the one selected
  if (parseInt(tarif) !== parseInt(offer.TARIF_NUM)) {
    res.status(400).json({status: 400, msg: "Une erreur est survenue, merci de re-sélectionner votre offre."})
    return res
  }

  // If it's for me and i'm connected, is the country ok
  if (action === "sub_renew") {
    const me = await customerSaleor.me()
    const countryUser = await userCountryOrDomTom(me)
    if (me && countryUser !== country) {
      res.status(400).json({status: 400, msg: "Merci de sélectionner le pays qui correspond à votre compte."})
      return res
    }
  }

  // Is the offer the same, we resave the offer to make sur informations are the same
  const offers = await abonnementPresseService.getOffers(country);
  let offerBack = offer;
  if (!offers || offers.filter(e => e.TARIF_NUM == parseInt(tarif)).length <= 0) {
    res.status(400).json({status: 400, msg: "Une erreur est survenue, merci de re-sélectionner votre offre."})
    return res
  } else if (offers && offers.filter(e => e.TARIF_NUM == parseInt(tarif)).length > 0) {
    offerBack = offers.find(e => e.TARIF_NUM == parseInt(tarif));
  } else {
    res.status(400).json({status: 400, msg: "Une erreur est survenue, merci de re-sélectionner votre offre."})
    return res
  }

  // Restrict to only one copies
  if (offer.TYPE_OFFER === "digital" && copies !== 1) {
    copies = 1;
  }

  // Send alert if exceed copies max and min
  if (offer.TYPE_OFFER === "full" && copies > 10) {
    res.status(400).json({status: 400, msg: "Le nombre maximum d'exemplaire est de 10."})
    return res
  } else if (copies < 1) {
    res.status(400).json({status: 400, msg: "Le nombre minimal d'exemplaire est de 1."})
    return res
  }

  const newSub = {action, country, tarif, copies, offer: offerBack, recipient, abo};

  // Add a recipient for this offer
  if (action === "gift" && offer.TYPE_OFFER !== "digital" && task === 2) {
    return addRecipient(req, res, newSub);
  }

  // Add to cart
  if (task === 3) {
    return addToCart(req, res, newSub);
  }

  subscriptionService.saveSubscription(newSub, req, res);

  return null;
}

const addRecipient = async (req, res, sub) => {
  const { recipient } = req.body;
  if (!recipient || !sub || !sub.offer) {
    res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
    return res
  }

  if (recipient === "create") {
    const { 
      civ, firstname, lastname, address1, address2, postcode, city,
      country, state, phone, company, email
    } = req.body;
    if (!firstname || !lastname || !address1 || !postcode || !city || !country) {
      res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
      return res
    }

    try {
      const data = await statePresseService.getStatesByCode(country)
      if ((data && data.length > 0 && !state) || (data.length > 0 && data.filter(e => e.etatabr === state).length <= 0)) {
        res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
        return res
      }
    } catch (err) {
      if (state) {
        res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
        return res
      }
    }
    const recipient = await individuPresseService.createRecipient({
      civ, firstname, lastname, address1, address2, postcode, city, country, state, phone, company, email
    })
    if (!recipient || !recipient.INDIV_NUM) {
      res.status(400).json({status: 400, msg: "Une erreur est survenue lors de l'ajout du bénéficiaire."})
      return res
    }

    sub.recipient = recipient.INDIV_NUM;
  }
  if (recipient === "search" || recipient === "resub") {
    const { 
      recipientId, abo
    } = req.body;
    
    if (!recipientId || !abo) {
      res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
      return res
    }

    sub.recipient = recipientId;
    sub.abo = abo;
  }

  subscriptionService.saveSubscription(sub, req, res);

  return null;
}

const addToCart = async (req, res, sub) => {
  const { renew, abo } = req.body;

  // the sub is for me
  if (sub.action === "sub_renew") {
    if (renew && renew === "1") {
      if (!abo) {
        res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
        return res
      }
      
      sub.abo = abo;
    }

  // the sub is a gift
  } else if (sub.action === "gift" && sub.offer.TYPE_OFFER !== "digital") {
    if (!sub.recipient) {
      res.status(400).json({status: 400, msg: publicRuntimeConfig.MISSING_PARAMS})
      return res
    }
    const recipientAdr = await individuPresseService.getIndividuAddress(sub.recipient);
    sub.recipientAdr = recipientAdr?.[0];
  }

  await cartSaleor.addSubscription(sub, req, res);
  subscriptionService.deleteSubscription(req, res);

  return null;
}