import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
const baseUrl = `${serverRuntimeConfig.API_PRESSE_URL}/country`;

import { axiosInstance } from '../../utils';

const getCountries = async (cp = null) => {
  try {
    return await axiosInstance.axiosGetFetcher(`${baseUrl}${cp && !isNaN(cp) ? `?cp=${cp}`: ''}`);
  } catch (err) {
    throw err;
  }
};

export const countryPresseService = { getCountries };
