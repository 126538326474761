import { parse, isValid, isMatch, format } from "date-fns";

export const formatToFront = (value) => {
  if (value && !isNaN(new Date(value)) && isValid(new Date(value))) {
    return format(new Date(value), "dd/MM/yyyy")
  }
  if (value && isMatch(value, "dd/MM/yyyy")) {
    return value || null
  }
  return null;
}

export const formatToBack = (value) => {
  if (value && isMatch(value, "dd/MM/yyyy")) {
    return parse(value, "dd/MM/yyyy", new Date()) || null
  }
  if (value && !isNaN(new Date(value)) && isValid(new Date(value))) {
    return value
  }
  return null;
}