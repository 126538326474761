import { useEffect, useState } from 'react';

import { AiFillCloseCircle } from 'react-icons/ai';
import { useAppContext } from '../../../lib/context';

export function Alert({ alert, index }) {
  const { setAlerts } = useAppContext();

  const [mounted, isMounted] = useState(false);
  const [color, setColor] = useState('text-blue-contrast');
  const [borderColor, setBorderColor] = useState('text-red-500')
  const [bg, setBg] = useState('bg-blue-light');
  const [height, setHeight] = useState('44');

  const [auto, setAuto] = useState(null);

  useEffect(() => {
    if (!mounted) {
      isMounted(true);

      defineColor();
      setHeight(index * 70 + 420);
      if (alert.time) {
        setAuto(setTimeout(dismissAlert, alert.time * 1000));
      }
    }
    return () => {
      clearTimeout(auto);
    };
  }, [mounted, isMounted, setColor, setBg, setAuto]);


  const defineColor = () => {
    switch (alert.status) {
      case 'info':
      default:
        setColor('text-blue-700');
        setBorderColor('border-blue-500')
        setBg('bg-blue-100');
        break;
      case 'success':
      case 200:
        setColor('text-teal-900');
        setBorderColor('border-teal-500')
        setBg('bg-teal-100');
        break;
      case 'warning':
        setColor('text-orange-700');
        setBorderColor('border-orange-500')
        setBg('bg-orange-100');
        break;
      case 'error':
      case 400:
      case 401:
      case 404:
      case 500:
        setColor('text-red-900');
        setBorderColor('border-red-500')
        setBg('bg-red-300');
        break;
    }
  }

  const dismissAlert = () => {
    setAlerts((prev) => [...prev.filter((_, i) => _.key !== alert.key)]);
  };

  return (
    <div
      onClick={dismissAlert}
      className={`fixed left-1/2 -translate-x-1/2 ${bg} border-t-4 w-80 ${borderColor} rounded-b ${color} px-4 py-3 shadow-md z-50`}
      style={{ top: `${height}px` }} role="alert">
      <div className="flex justify-between">
        <p className="font-bold text-sm">{alert.msg}</p>
        <div className="w-4"><AiFillCloseCircle size={22} className='cursor-pointer' /></div>

      </div>
    </div>

    // <div className={`absolute bottom-0 left-0 pt-6 pb-2 px-5 mb-4 text-sm ${color} ${bg} rounded-lg font-bold shadow-xl`} onClick={dismissAlert} role="alert">
    //   <AiFillCloseCircle className='text-xl cursor-pointer' />
    //   <span className="whitespace-pre-wrap font-medium">{alert.msg}</span>
    // </div>
  );
}
