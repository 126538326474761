import '../styles/globals.css';

import { settingService, authMagazineService, cartSaleor, customerSaleor, authService } from '../lib/services';
import { AppProvider } from '../lib/context';
import { SWRConfig } from 'swr';

import serverStore from '../lib/utils/serverStore';

import { Layout, CookieConsent } from '../lib/components';
import { Footer } from './../lib/components/layout';

import { ApolloProvider } from '@apollo/client';
import client, { setGraphContext } from '../apollo-client';

import { Source_Sans_Pro } from '@next/font/google'
import { axiosInstance } from '../lib/utils';
import Head from 'next/head';

const sourceSansPro = Source_Sans_Pro({
  weight: ['200', '300', '400', '600', '700', '900'],
  style: ['normal', 'italic'],
  variable: '--font-ssp',
  display: "swap",
  subsets: ['latin']
})

function MyApp({ Component, pageProps, router, fallback, isLogged, cart, props }) {
  const layoutNeeded = !router.pathname.includes('/facture')

  const getUrlFromReglages = (reglageName) => {
    const reglagesFiltered = props?.setting.filter(reglage => reglage.name == reglageName)[0]
    return reglagesFiltered?.item?.presignedUrl;
  }
  const getValueFromReglages = (reglageName) => {
    const reglagesFiltered = props?.setting.filter(reglage => reglage.name == reglageName)[0]
    return reglagesFiltered?.value;
  }

  return (
    <CookieConsent>
      {props && props.setting &&
        <Head>
          <title>{getValueFromReglages('title')}</title>
          <link rel="shortcut icon" href={getUrlFromReglages('favicon')} type="image/x-icon" />
          <link rel="icon" href={getUrlFromReglages('favicon')} type="image/x-icon" />
          <meta name="description" content={getValueFromReglages('description')}></meta>
        </Head>
      }
      <main className={`${sourceSansPro.variable} min-h-screen font-sans text-blue-darker text-2xl flex flex-col`}>
        <AppProvider>
          <ApolloProvider client={client}>
            {layoutNeeded &&
              <SWRConfig value={{ fallback }}>
                <Layout className="flex-1" isLogged={isLogged} cart={cart} />
              </SWRConfig>
            }
            <Component {...pageProps} />
            {layoutNeeded && <Footer />}
          </ApolloProvider>
        </AppProvider>
      </main>
    </CookieConsent>
  );
}

MyApp.getInitialProps = async (context) => {
  axiosInstance.setAxiosContext(context.ctx.req, context.ctx.res)
  setGraphContext(context.ctx.req, context.ctx.res)

  if (authMagazineService.getToken(context.ctx.req, context.ctx.res) == null) {
    await authMagazineService.login(context.ctx.req, context.ctx.res);
  }
  let cart = null
  let isLogged = false
  try {
    const refresh = authService.getRefresh(context.ctx.req, context.ctx.res);
    const verify = refresh ? await customerSaleor.verifyToken(refresh) : { isValid: false };
    cart = await cartSaleor.checkout(context.ctx.req, context.ctx.res);
    isLogged = verify?.isValid ?? false;
  } catch (e) {

  }



  //TODO: est-ce qu'on garde la création tu cart ici ?
  // await cartSaleor.checkout(context.ctx.req, context.ctx.res);
  // if (!cartSaleor.getCartToken(context.ctx.req, context.ctx.res)) {
  //   await cartSaleor.checkoutCreate(context.ctx.req, context.ctx.res);
  // } else {
  //   const checkout = await cartSaleor.checkout(context.ctx.req, context.ctx.res);
  //   if (!checkout) {
  //     await cartSaleor.checkoutCreate(context.ctx.req, context.ctx.res);
  //   }
  // }
  // const checkout = await cartSaleor.checkout(context.ctx.req, context.ctx.res);
  // if (!checkout) {
  //   await cartSaleor.checkoutCreate(context.ctx.req, context.ctx.res);
  // }

  let setting = await settingService.getSetting();

  serverStore.set('setting', setting);

  let fallback = {};
  fallback[settingService.SETTING_GET_SETTING_PATH] = setting;

  return {
    fallback: fallback,
    isLogged,
    cart,
    props: { setting: setting },
  };
};

export default MyApp;
