import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
const baseUrl = `${serverRuntimeConfig.API_PRESSE_URL}/contact`;

import { axiosInstance } from '../../utils';

const sendContact = async (contact, name, email, subject, message, rgpd) => {
  try {
    return await axiosInstance.axiosPostFetcher(`${baseUrl}`, {contact, name, email, subject, message, rgpd});
  } catch (err) {
    throw err;
  }
};

export const contactPresseService = { sendContact };
