export const displayRecipient = (line, user) => {
  const type = line?.metadata?.find(el => el.key === "TYPE")?.value;
  const action = line?.metadata?.find(el => el.key === "ACTION")?.value;
  const recipientAdr = line?.metadata?.find(el => el.key === "RECIPIENT_ADR")?.value ?
    JSON.parse(line?.metadata?.find(el => el.key === "RECIPIENT_ADR").value) : null;
  if (action === "sub_renew") {
    return (
      <div className="mt-3 text-base whitespace-pre">
        <span className="block underline">Bénéficiaire :</span>
        {
          (user?.metadata?.find(el => el.key === "PREFIX")?.value?.trim() ? user?.metadata?.find(el => el.key === "PREFIX")?.value?.trim() + " " : "") +
          (user?.defaultShippingAddress?.lastName ? user?.defaultShippingAddress?.lastName + " " : "") +
          (user?.defaultShippingAddress?.firstName ? user?.defaultShippingAddress?.firstName + " " : "") +
          "\r\n"
        }
        {user?.defaultShippingAddress?.companyName ? user?.defaultShippingAddress?.companyName + "\r\n" : ""}
        {user?.defaultShippingAddress?.streetAddress1 ? user?.defaultShippingAddress?.streetAddress1 + "\r\n" : ""}
        {user?.defaultShippingAddress?.streetAddress2 ? user?.defaultShippingAddress?.streetAddress2 + "\r\n" : ""}
        {user?.defaultShippingAddress?.postalCode ? user?.defaultShippingAddress?.postalCode + " " : ""}
        {user?.defaultShippingAddress?.city ? user?.defaultShippingAddress?.city : ""}
        {"\r\n"}
        {user?.defaultShippingAddress?.country?.country ? user?.defaultShippingAddress?.country?.country : ""}
      </div>
    )
  } else if (recipientAdr && action === "gift") {
    return (
      <div className="mt-3 text-base whitespace-pre">
        <span className="block underline">Bénéficiaire :</span>
        {
          (recipientAdr?.TITRE_NUM ? recipientAdr?.TITRE_NUM?.trim() + " " : "") +
          (recipientAdr?.NOM_NUM ? recipientAdr?.NOM_NUM?.trim() + " " : "") +
          (recipientAdr?.PRENOM_NUM ? recipientAdr?.PRENOM_NUM?.trim() + " " : "") +
          "\r\n"
        }
        {recipientAdr?.ADR_COMP1?.trim() ? recipientAdr?.ADR_COMP1?.trim() + "\r\n" : ""}
        {recipientAdr?.ADR_ADR?.trim() ? recipientAdr?.ADR_ADR?.trim() + "\r\n" : ""}
        {recipientAdr?.ADR_COMP2?.trim() ? recipientAdr?.ADR_COMP2?.trim() + "\r\n" : ""}
        {recipientAdr?.ADR_CP ? recipientAdr?.ADR_CP?.trim() + " " : ""}
        {recipientAdr?.ADR_VILLE ? recipientAdr?.ADR_VILLE?.trim() : ""}
        {"\r\n"}
        {recipientAdr?.MUM_PAYS ? recipientAdr?.MUM_PAYS?.trim() : ""}
      </div>
    )
  } else if (!recipientAdr && type === "digital" && action === "gift") {
    return (
      <div className="mt-3 text-base whitespace-pre">
        <span className="block">Abonnement cadeau</span>
      </div>
    )
  }
  return null;
}