import { setCookie, getCookie, deleteCookie, hasCookie } from 'cookies-next';

const ALERT_COOKIE_NAME = "alert-temp";

function saveAlert(alert, req, res) {
  if (hasCookie(ALERT_COOKIE_NAME, {req, res})) {
    let alerts = getAlert(req, res);
    alerts = alerts ? alerts : [];

    alerts.push(alert)
    setCookie(ALERT_COOKIE_NAME, alerts, { req, res });
  } else {
    setCookie(ALERT_COOKIE_NAME, [alert], { req, res });
  }
}

function getAlert(req, res) {
  const alerts = getCookie(ALERT_COOKIE_NAME, { req, res });
  return alerts ? JSON.parse(alerts) : null
}

function deleteAlert(req, res) {
  deleteCookie(ALERT_COOKIE_NAME, { req, res });
}

export const alertService = {
  saveAlert, getAlert, deleteAlert
};
