import { useEffect } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

export function Popup({children, closeAction}) {
  const myFunction = () => {
    closeAction();
  };

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        myFunction();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    document.body.style.overflow = 'hidden';
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div 
      className="fixed top-0 left-0 z-30 w-screen h-screen bg-gray-500/50 flex justify-center items-center"
    >
      <div 
        className="absolute top-0 left-0 w-full h-full cursor-pointer"
        onClick={() => closeAction()}
      >
      </div>
      <span className="absolute top-0 right-0 m-2 text-black">Echap pour fermer</span>
      <div className="relative shadow-xl rounded bg-white p-6 z-40 w-full md:w-3/4">
        <AiFillCloseCircle 
          className="absolute text-pink hover:text-pink-light text-xl cursor-pointer top-0 right-0 m-1"
          onClick={() => closeAction()}
        />
        {children}
      </div>
    </div>
  )
}
