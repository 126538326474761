import { useEffect, useState, Fragment } from "react";
import { AiFillExclamationCircle } from "react-icons/ai"
import { BsAsterisk } from "react-icons/bs"
import { ToolTip } from "../ToolTip";

export function TextArea({
  input, onChange, meta = {}, label = "", classContainer = "", classInput = "",
  asterisk = false, action = null, infoInput = null, ...rest
}) {
  const valueReturned = (
    <label className={
      "text-lg font-semibold " + (input?.type === "checkbox" || input?.type === "radio" ? "flex flex-row-reverse justify-end items-center flex-wrap " : "") + classContainer
    }>
      {action ?
        <Fragment>
          {label}
          {asterisk && <BsAsterisk className="inline text-xxs align-super text-pink " />}
          {action}
        </Fragment>
      :
        <Fragment>
          {label}
          {asterisk && <BsAsterisk className="inline text-xxs align-super text-pink " />}
        </Fragment>
      }
      <textarea 
        {...input}
        {...rest}
        onKeyDown={(e) => {
          if ((input.type === "password" || input.type === "email") && e.code === 'Space') e.preventDefault()
        }}
        onChange={(e) => {
          //TODO: trim space after validation to let user have space in name or firstname
          if (input?.type === "checkbox") {
            input?.onChange(e.target.checked)
          } else {
            input?.onChange(e.target?.value.trimStart()/*?.trim()*/)
          }
          if (onChange) onChange(e);
        }}
        className={`
          h-10 bg-gray-50 border block w-full px-2 py-1 font-normal text-black
          disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-100 `
          + (classInput.includes("rounded-") ? "" : " rounded-md ")
          + classInput
          + (meta.touched && meta.error ? " border-pink bg-pink-light opacity-40 " : " border-gray-300 ")
          + (input?.type === "checkbox" || input?.type === "radio" ? ` w-4 ${label ? "mr-2" : ""} ` : "")
        }
      />
      {infoInput && 
        <p className="text-sm font-normal text-gray-500">{infoInput}</p>
      }
      {meta.touched && meta.error && 
        <span className={"flex items-center mb-3 text-pink font-normal " + (input.type === "checkbox" || input.type === "radio" ? "w-full" : "")}>
          <AiFillExclamationCircle className="mr-1" />{meta.error}
        </span>
      }
    </label>
  );
  return valueReturned;
}
