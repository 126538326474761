import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Form, Input } from '../form';

import { Field } from 'react-final-form'
import { customerService } from '../../../lib/services';
import { useAppContext } from '../../../lib/context';
import { Button } from '../Button';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export function EditNewsletter({ initialValues, setUserNewsletter, close }) {
  const { addAlert } = useAppContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const router = useRouter();

  const submit = async (values) => {
    setIsProcessing(true)
    try {
      if (!executeRecaptcha) {
        addAlert({
          status: "error",
          msg: publicRuntimeConfig.ERROR_500
        })
        setIsProcessing(false);
        return;
      };
      const token = await executeRecaptcha();
      if (!token) {
        addAlert({
          status: "error",
          msg: publicRuntimeConfig.ERROR_500
        })
        setIsProcessing(false);
        return;
      }
      values.token = token;

      const data = await customerService.updateNewsletter(values.indivnum, values);
      if (data.data) setUserNewsletter(data.data)
      if (data) addAlert(data)
      close();
      // authService.reloadUser();
      router.replace(router.asPath);
    } catch (err) {
      addAlert({
        status: err?.response?.data?.status !== 200 ? "error" : "success",
        msg: err?.response?.data?.msg
      })
    }
    setIsProcessing(false)
  }

  return (
    <Form
      formName="edit-newsletter"
      onSubmit={submit}
      initialValues={initialValues}
    >
      <div className="grid grid-cols-6 gap-4">
        {
          initialValues.newsletters
          && Array.isArray(initialValues.newsletters)
          && initialValues.newsletters.map((newsletter, index) =>
            <Field
              key={"news-" + newsletter.NEWS_ID}
              classContainer="col-span-6"
              component={Input}
              label={initialValues.newsletters.length === 1 ? "Oui, je souhaite recevoir la newsletter" : newsletter?.NEWS_LIBELLE_WEB ?? newsletter.NEWS_LIBELLE}
              type="checkbox"
              name={`newsletters[${index}].IS_SUBSCRIBED`}
            />
          )}
        <Button
          link={false}
          disabled={isProcessing}
          isLoading={isProcessing}
          type="submit"
          text="Enregistrer"
          className="px-10 py-3 text-lg h-fit self-end"
        />
      </div>
    </Form>
  );
}
