import sanitizeHtml from 'sanitize-html';

export function SanitizeHTML({
  html,
  container = true,
  magazine = false,
  allowedTags = magazine ?
    ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'ol', 'ul', 'li', 'b', 'i', 'em', 'sup', 's', 'strong', 'q', 'br', 'a', 'img']
    : ['h1', 'h2', 'h3', 'p', 'ol', 'ul', 'li', 'b', 'i', 'em', 'sup', 's', 'strong', 'q', 'br'],
  allowedAttributes = magazine ? {
    'a': [ 'href', 'target' ],
    'img': [ 'src' ],
    'p': [ 'style' ],
    'h1': [ 'style' ],
    'h2': [ 'style' ],
    'h3': [ 'style' ],
    'h4': [ 'style' ],
    'h5': [ 'style' ],
    'ol': [ 'style' ],
    'ul': [ 'style' ],
    'q': [ 'style' ],
  } : {},
  ...input
}) {
  const clean = sanitizeHtml(html, {
    allowedTags: allowedTags,
    allowedAttributes
  });

  if (container) {
    return (
      <div {...input} dangerouslySetInnerHTML={{ __html: clean }}>
      </div>
    )
  } else {
    return clean
  }
}

export function sanitizeHTML(
  html,
  allowedTags = ['b', 'i', 'em', 'sup', 's', 'strong', 'br']
) {
  return sanitizeHtml(html, {
    allowedTags: allowedTags
  });
}