/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api`;
async function send(params) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/contact`, params);
}

export const contactService = {
  send
};
