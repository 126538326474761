import { useRouter } from 'next/router';
import { useState } from 'react';

import { authService } from '../../services';
import { useAppContext } from '../../context';

import { Form, Field } from 'react-final-form';
import { Input } from '../../components/form';
import { Button } from '../../components';

import Link from 'next/link';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import getConfig from 'next/config';
import { composeValidators, maxLength, required, emailFirefox, noSpace } from '../../utils';
const { publicRuntimeConfig } = getConfig();

export function Login({ redirectNoChange = false }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { addAlert } = useAppContext();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const router = useRouter();

  const submitFunction = async (values) => {
    try {
      setIsProcessing(true);
      if (!executeRecaptcha) {
        addAlert({
          status: "error",
          msg: publicRuntimeConfig.ERROR_500
        })
        setIsProcessing(false);
        return;
      };
      const token = await executeRecaptcha();
      if (!token) {
        addAlert({
          status: "error",
          msg: publicRuntimeConfig.ERROR_500
        })
        setIsProcessing(false);
        return;
      }
      await authService.login(values.email, values.password, token);
      if (redirectNoChange) {
        router.reload(window.location.pathname)
      } else {
        router.push('/mon-espace');
      }
    } catch (err) {
      setIsProcessing(false);
      addAlert({
        status: err?.response?.data?.status,
        msg: err?.response?.data?.msg,
      });
    }
  };

  return (
    <div>
      <div>
        <h2 className="mb-5 text-xl">Connectez-vous à votre espace client</h2>
        <Form
          onSubmit={submitFunction}
          render={({ handleSubmit }) => (
            <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
              <Field component={Input} validate={composeValidators(required, emailFirefox, noSpace, maxLength(100))} label="Email" type="email" name="email" />
              <Field component={Input} validate={composeValidators(required, maxLength(20))} label="Mot de passe" type="password" name="password" />
              <div className="flex flex-col items-center">
                <Button
                  color="none"
                  link={true}
                  action="/password/lost"
                  className="underline text-base self-end mb-5 font-normal"
                  text="Mot de passe oublié"
                />
                <Button
                  isLoading={isProcessing}
                  disabled={isProcessing}
                  link={false}
                  type="submit"
                  text="Me connecter"
                  className="px-10 py-4 text-lg uppercase"
                />
              </div>
            </form>
          )}
        />
      </div>
      <div className="mt-10 pt-6 flex flex-col items-center">
        <h2 className="mb-5 text-3xl text-center">Je suis abonné.e et j'accède à mon compte pour la première fois</h2>
        <Button
          link={true}
          type="button"
          action="/inscription?abonner=oui"
          text="Je crée mon mot de passe"
          className="px-10 py-4 text-lg uppercase"
        />
      </div>
    </div>
  );
}
