import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
const baseUrl = `${serverRuntimeConfig.API_PRESSE_URL}/auth`;

import { axiosInstance } from '../../utils';

const login = async (email, password) => {
  try {
    return await axiosInstance.axiosPostFetcher(`${baseUrl}/login`, {
      email,
      password,
    });
  } catch (err) {
    throw err;
  }
};

const register = async (args) => {
  try {
    return await axiosInstance.axiosPostFetcher(`${baseUrl}/register`, args);
  } catch (err) {
    throw err;
  }
};

const lostPassword = async (args) => {
  try {
    return await axiosInstance.axiosPostFetcher(`${baseUrl}/lost-password`, args);
  } catch (err) {
    throw err;
  }
};

const recoverPassword = async (args) => {
  try {
    return await axiosInstance.axiosPostFetcher(`${baseUrl}/recover-password`, args);
  } catch (err) {
    throw err;
  }
};

export const authPresseService = { login, register, lostPassword, recoverPassword };
