import Image from 'next/image';
import Link from 'next/link';
import { Button } from '../Button';

export function Footer() {
  return (
    <footer>
      <nav className="bg-pink mt-16 py-8 text-white text-lg">
        <ul className="grid grid-cols-1 sm:grid-cols-3 grid-rows-2 gap-4 md:w-3/4 mx-5 md:mx-auto">
          <li className="row-span-2 ">
            <a target="_blank" href="http://www2.occe.coop/">
              <Image
                className="mx-auto sm:mx-0"
                src="/imgs/logo/OCCE_LogoBlanc.png"
                alt="Logo de l'OCCE en blanc"
                width={149}
                height={94}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </a>
          </li>
          <li className="text-center">
            <Button
              color="none"
              link={true}
              action="/mentions-legales"
              className="text-white font-normal w-auto inline"
              responsive={false}
              text="Mentions légales"
            />
          </li>
          <li className="text-center sm:text-right">
            <Button
              color="none"
              link={true}
              action="/cgv"
              className="text-white font-normal w-auto inline"
              responsive={false}
              text="CGV"
            />
          </li>
          <li className="text-center">
            <Button
              color="none"
              link={true}
              action="/equipe"
              className="text-white font-normal w-auto inline"
              responsive={false}
              text="Notre équipe"
            />
          </li>
          <li className="text-center sm:text-right">
            <Button
              color="none"
              link={true}
              action="/contact"
              className="text-white font-normal w-auto inline"
              responsive={false}
              text="Contact"
            />
          </li>
        </ul>
      </nav>
    </footer>
  )
}
