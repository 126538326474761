import { parse, isValid, isMatch, format } from "date-fns";

/****************************/
/**** AU NIVEAU DU FORM ****/
/****************************/
// Validation du plusieurs règles au niveau formulaire
export const compose = (...validators) => (values) => 
  validators.reduce((errors, validator) => {return {...errors, ...validator(values)}}, {});

// Requis si le champs suivant est rempli
export const requiredIfExist = (inputToExist, inputToRequired) => (values) =>
  values && values[inputToExist] && !values[inputToRequired] ? {[inputToRequired]: "Champ requis"} : {};

// Requis si le champs suivant change
export const requiredIfChange = (inputToExist, initial, inputToRequired) => (values) =>
  values && values[inputToExist] && values[inputToExist] !== initial && !values[inputToRequired] ? {[inputToRequired]: "Champ requis"} : {};

// Les deux champs doivent avoir la même value
export const sameValues = (input, inputToCompare, inputName) => (values) =>
  values && values[input] && values[inputToCompare] && values[input] !== values[inputToCompare] ? {
    [inputToCompare]: "Doit être similaire à "+inputName
  } : {};

/****************************/
/**** AU NIVEAU DU CHAMP ****/
/****************************/
// Pour utiliser plusieurs règles avec Final Form
export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

// Requis mais peut être zero
export const required = (value) =>
  (!value && value !== 0) || (typeof value === "string" && value.trim() === "") ? "Champ requis" : undefined;

// Requis et non zero
export const requiredAndNot0 = (value) =>
  !value || value == 0 || (typeof value === "string" && value.trim() === "") ? "Champ requis" : undefined;

export const regex = (re, examples) => (value) => {
  if (value && re) {
    if (Array.isArray(re)) {
      if (re.length === 0) return undefined;
      let bool = false;
      for (let i = 0; i < re.length; i++) {
        if (RegExp(re[i]).test(value)) {
          bool = true;
          break;
        }
      }
      return !bool ? "Ne respecte pas le format" + (examples ? (Array.isArray(examples) ? ` (ex : ${examples.join(", ")})` : ` (ex : ${examples})`) : "") : undefined
    } else {
      return !RegExp(re).test(value) ? "Ne respecte pas le format" + (examples ? (Array.isArray(examples) ? ` (ex : ${examples.join(", ")})` : ` (ex : ${examples})`) : "") : undefined
    }
  }
  return undefined;
}

// Si présent doit être un chiffre
export const number = (value) =>
  value && isNaN(Number(value)) ? "Chiffre requis" : undefined;

export const numberPositive = (value) =>
  value && !isNaN(Number(value)) && value < 0
    ? "Chiffre positif requis"
    : undefined;

export const minLength = (max) => (value) =>
  value && value.length < max ? `Au mininum ${max} caractères requis` : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Au maximum ${max} caractères requis` : undefined;

export const min = (max) => (value) =>
  value && value < max ? `Au mininum ${max}` : undefined;

export const max = (max) => (value) =>
  value && value > max ? `Au maximum ${max}` : undefined;

export const email = (value) =>
  value && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)
    ? "L'email est invalide"
    : undefined;

// Test email with standard HTML5 / Firefox validation regex
export const emailFirefox = (value) =>
value && !/^[a-z0-9.!#$%&'*+\-\/=?^_`{|}~]+@(?=[a-z0-9])(?:[a-z0-9]|(?<![.-])\.|(?<!\.)-)*[a-z0-9]$/ig.test(value)
  ? "L'email est invalide"
  : undefined;

// export const password = (value) =>
//   value && 
//   !/^(?=.*\d{3,})(?=.*[a-z]{4,})(?=.*[A-Z]{3,})(?=.*[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?!]{1,})[0-9a-zA-Z@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?!]{8,}$/g.test(value)
//     ? "Le mot de passe ne contient pas les caractères requis"
//     : undefined

export const password = (value) =>
  value && 
  !/(?=.*[A-Za-z])(?=.*\d)(?=.*[@#!_*.?-])[A-Za-z\d@#!_*.?-]{8,20}/.test(value)
    ? "Le mot de passe ne contient pas les caractères requis"
    : undefined

export const noSpace = (value) =>
  value && value.indexOf(" ") >= 0 
    ? "Ne doit pas contenir d'espace"
    : undefined

export const date = (value) =>
  value && !isValid(value) ? 'La date est invalide' : undefined