import { countryPresseService, countryService } from "../services";

export const userCountryOrDomTom = async (user) => {
  const cp = user?.defaultShippingAddress?.postalCode?.substring(0, 3)
  const country = user?.defaultShippingAddress?.country?.code
  if (cp && country && country === "FR" && domTomArray().includes(parseInt(cp))) {
    if (typeof window === 'undefined') {
      const countries = await countryPresseService.getCountries(cp)
      return countries?.[0]?.code ?? country
    } else {
      const countries = await countryService.getCountries(cp)
      return countries?.[0]?.code ?? country
    }
  }
  return country ?? null
}

export const domTomArray = () => [
  971, 972, 973, 974, 975, 976, 977, 978,
  984, 986, 987, 988, 989
]