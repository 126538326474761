import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
const baseUrl = `${serverRuntimeConfig.API_PRESSE_URL}/individu`;
const searchNameUrl = `${baseUrl}/name`;
const searchAdrUrl = `${baseUrl}/address`;

import { axiosInstance } from '../../utils';

const searchByName = async (lastName, indivNum) => {
  try {
    return await axiosInstance.axiosGetFetcher(
      searchNameUrl + `?lastname=${lastName}&indivnum=${indivNum}`,
    );
  } catch (err) {
    throw err;
  }
};

const searchByAdr = async (firstName = null, lastName, postCode, city, country = 'FR') => {
  try {
    return await axiosInstance.axiosGetFetcher(
      searchAdrUrl +
      `?${firstName ? 'firstname=' + firstName : ''
      }lastname=${lastName}&cp=${postCode}&city=${city}&country=${country}`,
    );
  } catch (err) {
    throw err;
  }
};

const getSubscriptions = async (indivNum, publiNum = null) => {
  try {
    return await axiosInstance.axiosGetFetcher(
      baseUrl +
      (publiNum && !isNaN(publiNum)
        ? `/${indivNum}/subscription/${publiNum}`
        : `/${indivNum}/subscription`),
    );
  } catch (err) {
    if (err.response.data && err.response.data.status === 404) {
      return null;
    }
    throw err;
  }
};

const getRecipients = async (indivNum, publiNum) => {
  try {
    return await axiosInstance.axiosGetFetcher(
      baseUrl + `/${indivNum}/subscription/${publiNum}/recipient`,
    );
  } catch (err) {
    if (err.response.data && err.response.data.status === 404) {
      return null;
    }
    throw err;
  }
};

const getNewsletters = async (indivNum) => {
  try {
    return await axiosInstance.axiosGetFetcher(baseUrl + `/${indivNum}/newsletter`);
  } catch (err) {
    throw err;
  }
};

const getOrders = async (indivNum) => {
  try {
    return await axiosInstance.axiosGetFetcher(baseUrl + `/${indivNum}/order`);
  } catch (err) {
    if (err.response.data.code == '404.0001') {
      return {};
    }
    throw err;
  }
};

const getInvoice = async (indivNum, facNum) => {
  try {
    return await axiosInstance.axiosGetFetcher(baseUrl + `/${indivNum}/invoice/${facNum}`);
  } catch (err) {
    throw err;
  }
};

const getIndividu = async (token) => {
  try {
    return await axiosInstance.axiosGetFetcher(baseUrl + `/`, {
      headers: {
        token,
      },
    });
  } catch (err) {
    throw err;
  }
};

const getIndividuAddress = async (indivNum) => {
  try {
    return await axiosInstance.axiosGetFetcher(baseUrl + `/${indivNum}/address`);
  } catch (err) {
    throw err;
  }
};

const getIndividuRight = async (indivNum) => {
  try {
    return await axiosInstance.axiosGetFetcher(baseUrl + `/${indivNum}/subscription/right`);
  } catch (err) {
    throw err;
  }
};

const updateIndividu = async (indivNum, args) => {
  try {
    return await axiosInstance.axiosPutFetcher(baseUrl + `/${indivNum}`, args);
  } catch (err) {
    throw err;
  }
};

const updateAddress = async (indivNum, args) => {
  try {
    return await axiosInstance.axiosPutFetcher(baseUrl + `/${indivNum}/address`, args);
  } catch (err) {
    throw err;
  }
};

const emailChange = async (email) => {
  try {
    return await axiosInstance.axiosPostFetcher(baseUrl + `/email`, { email });
  } catch (err) {
    throw err;
  }
};

const confirmEmailChange = async (indivNum, email) => {
  try {
    return await axiosInstance.axiosPutFetcher(baseUrl + `/${indivNum}/email`, { email });
  } catch (err) {
    throw err;
  }
};

const createRecipient = async (values) => {
  try {
    return await axiosInstance.axiosPostFetcher(baseUrl + `/recipient`, values);
  } catch (err) {
    throw err;
  }
};

export const individuPresseService = {
  searchByName,
  searchByAdr,
  getSubscriptions,
  getRecipients,
  getNewsletters,
  getOrders,
  getInvoice,
  getIndividu,
  getIndividuAddress,
  updateIndividu,
  updateAddress,
  emailChange,
  confirmEmailChange,
  createRecipient,
  getIndividuRight
};
