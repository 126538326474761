import { Field } from 'react-final-form'
import { Button } from '../Button';
import { Form, Input, Select } from '../form';

export function SearchByAdr({submit}) {
  return (
    <Form formClass="flex flex-col" onSubmit={submit} initialValues={{country: "FR"}}>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Field
          component={Input}
          label="Prénom"
          type="text"
          name="firstname"
        />
        <Field
          classContainer="whitespace-nowrap"
          component={Input}
          asterisk={true}
          label="Nom"
          type="text"
          name="lastname"
        />
        <Field
          component={Input}
          asterisk={true}
          label="Code postal"
          type="text"
          name="postcode"
        />
        <Field
          component={Input}
          asterisk={true}
          label="Ville"
          type="text"
          name="city"
        />
        <Field
          component={Select}
          asterisk={true}
          // disabled={true}
          list={["country"]}
          label="Pays"
          type="text"
          name="country"
        />
        <Button
          link={false}
          plain={false}
          type="submit"
          text="Rechercher"
          className="px-10 py-3 text-lg h-fit self-end"
        />
      </div>
    </Form>
  );
}