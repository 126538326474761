/* NEXT IMPORT */
import getConfig from 'next/config';

/* OTHER LIB IMPORT */
import axios from 'axios';
import parseCacheControlHeader from 'parse-cache-control';

/* OUR CODE IMPORT */
import { authMagazineService } from '../services';

import { URL } from 'url';

const { publicRuntimeConfig } = getConfig();
const axiosInstance = axios.create({ log: false });

let context;
axiosInstance.setAxiosContext = (req, res) => (context = { req: req, res: res });

axiosInstance.interceptors.request.use((config) => {
  // if (typeof window !== "undefined") console.error("CLIENT SIDE AXIOS ALARM !", config.url)
  const isRequestMag = config.url.includes(publicRuntimeConfig.API_MAGAZINE_URL)

  if (isRequestMag && authMagazineService.getToken(context?.req ?? null, context?.res ?? null))
    config.headers.Authorization = "Bearer " + authMagazineService.getToken(context?.req ?? null, context?.res ?? null);

  const edition = publicRuntimeConfig.EDITION_CODE;
  if (edition != null) config.headers.edition = edition;

  const canal = publicRuntimeConfig.CANAL_CODE;
  if (canal != null) config.headers.canal = canal;

  return config;
});

// export const axiosGetFetcher = (url) => axiosInstance.get(url).then((res) => res.data);
axiosInstance.axiosPutFetcher = (url, data) =>
  axiosInstance.put(url, data).then((res) => res.data);

axiosInstance.axiosPostFetcher = (url, data) =>
  axiosInstance.post(url, data).then((res) => res.data);

axiosInstance.axiosDeleteFetcher = (url) =>
  axiosInstance.delete(url).then((res) => res.data);

axiosInstance.axiosGetFetcher = async (url, params = {}) => {
  try {
    let data = null;
    if (typeof window !== 'undefined') {
      data = localStorage.getItem(url + '_refresh_date');
    }
    if (data) {
      data = new Date(parseInt(data));
      data.setMinutes(data.getMinutes() + 30);
    }
    if (data && data.getTime() > new Date().getTime()) {
      //console.log(`Data : ${url} loaded on LocalStorage`);
      return JSON.parse(localStorage.getItem(url));
    } else {
      //console.log(`Data : ${url} loaded on Request`);
      const response = await axiosInstance.get(url, params);

      if (typeof window !== 'undefined') {
        const cacheControl = parseCacheControlHeader(response.headers['cache-control']);
        if (cacheControl != null && cacheControl['max-age'] > 0) {
          let futurDate = new Date();
          futurDate.setMilliseconds(futurDate.getMilliseconds() + cacheControl['max-age'] * 1000);
          localStorage.setItem(url + '_refresh_date', futurDate.getTime());
          localStorage.setItem(url, JSON.stringify(response.data));
        }
      }

      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export { axiosInstance };
