import Link from 'next/link';
import { useEffect, useState } from 'react';

export function Button({
  link = true, action, text = "", plain = true, color = "pink",
  className = "", responsive = true, rounded = true, type = "button",
  children = null, disabled = false, isLoading = false, target = null
}) {
  const switchColor = (type = null) => {
    switch (color) {
      case "blue-dark":
        if (type === "text") return (plain ? "text-white" : "text-blue-dark")
        return (plain ?
          "bg-blue-dark hover:bg-blue" :
          "bg-white border-2 border-blue-dark hover:bg-blue-dark hover:text-white"
        )
        break;
      case "blue-darker":
        if (type === "text") return (plain ? "text-white" : "text-blue-darker")
        return (plain ?
          "bg-blue-darker hover:bg-blue" :
          "bg-white border-2 border-blue-darker hover:bg-blue-darker hover:text-white"
        )
        break;
      case "blue":
        if (type === "text") return (plain ? "text-white" : "text-blue")
        return (plain ?
          "bg-blue hover:bg-blue-light" :
          "bg-white border-2 border-blue hover:bg-blue hover:text-white"
        )
        break;
      case "orange":
        if (type === "text") return (plain ? "text-white" : "text-orange")
        return (plain ?
          "bg-orange hover:bg-orange-light" :
          "bg-white border-2 border-orange hover:bg-orange hover:text-white"
        )
        break;
      case "green":
        if (type === "text") return (plain ? "text-white" : "text-green")
        return (plain ?
          "bg-green hover:bg-green-light" :
          "bg-white border-2 border-green hover:bg-green hover:text-white"
        )
        break;
      case "none":
        return "";
        break;
      case "pink":
      default:
        if (type === "text") return (plain ? "text-white" : "text-pink")
        return (plain ?
          "bg-pink hover:bg-pink-light" :
          "bg-white border-2 border-pink hover:bg-pink hover:text-white"
        )
        break;
    }
  }

  const [textColor, setTextColor] = useState(switchColor("text"));
  const [bgColor, setBgColor] = useState(switchColor());

  const classesName = `
    transition duration-150 ease-out ${className?.includes("inline") ? "" : "flex items-center justify-center"} disabled:opacity-75
    text-center ${bgColor} font-bold ${!className?.includes("text-blue-darker") ? textColor : ""}
    ${rounded ? " rounded-[18px] " : ""}
    ${responsive ? " w-full xs:w-fit " : (!className?.includes("w-") ? " w-fit " : "")} 
    ${className} 
  `;

  if (link) {
    return (
      <Link href={action} target={target} className={classesName}>
        {text}
        {children}
      </Link>
    );
  } else {
    return (
      <button
        type={type}
        onClick={action ? () => action() : null}
        className={classesName}
        disabled={disabled}
      >
        {isLoading && (
          <svg className="h-6 w-6 animate-spin mr-2" viewBox="3 3 18 18">
            <path
              className="fill-gray-200"
              d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
            ></path>
            <path
              className="fill-gray-800"
              d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
            ></path>
          </svg>
        )}
        {text}
        {children}
      </button>
    );
  }
}
