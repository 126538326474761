import getConfig from 'next/config';
import * as Sentry from "@sentry/nextjs";
const { publicRuntimeConfig } = getConfig();

export const manageError = (res, err) => {
  //
  Sentry.captureException(err);
  if (err.response?.data?.errors) {
    // res.status(500).json(err?.response?.data?.errors)
    console.log('1', err.response.data.errors);

    // Retour erreur webservice
  } else if (err.response?.data) {
    // res.status(err.response.data.status || 500).json(err?.response?.data)
    console.log('2', err.response.data);
    if (err.response.data.status && err.response.data.msg) {
      return res.status(err.response.data.status || 500).json(err.response.data);
    }

    //
  } else if (err.data) {
    // res.status(err.data.status || 500).json(err.data)
    console.log('3', err.data);

    // Retour des Errors (axios, reference...)
  } else {
    // res.status(err.status || 500).json(err)
    if (err.networkError && err.networkError.result && err.networkError.result.errors) {
      console.log('4.1', err.networkError.result.errors);
    } else {
      console.log('4.2', err);
      if (err[0] && err[0].field === "oldPassword" && err[0].message === "Old password isn't valid.") {
        return res.status(400).json({
          status: 400,
          msg: "Le mot de passe actuel est incorrect"
        });
      }
      if (err[0] && err[0].field === "postalCode" && err[0].message === "This value is not valid for the address.") {
        return res.status(400).json({
          status: 400,
          msg: "Le code postal ne correspond pas au pays"
        });
      }
      if (err[0] && err[0].field === "newEmail" && err[0].message === "Email is used by other user.") {
        return res.status(400).json({
          status: 400,
          msg: "L'adresse email est déjà utilisé"
        });
      }
      if (
        (err[0] && err[0].field === "quantity" && err[0].message.includes("Cannot add more than 1 times this item"))
        || (err.message && err.message === "limit-product")
      ) {
        return res.status(400).json({
          status: 400,
          msg: "Vous ne pouvez pas commander ce numéro plus d'une fois"
        });
      }
    }
  }

  if (err && err.status && err.msg) {
    return res.status(err.status || 500).json(err);
  } else {
    return res.status(500).json({
      status: 500,
      msg: publicRuntimeConfig.ERROR_500,
    });
  }
};
