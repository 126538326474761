import getConfig from 'next/config';
import { axiosInstance } from '../../utils';

const { publicRuntimeConfig } = getConfig();

/* PATH */
const BASE_URL = `${publicRuntimeConfig.API_MAGAZINE_URL}/reglage`;
const SETTING_GET_SETTING_PATH = `${BASE_URL}/edition`;

export function getSetting() {
  return axiosInstance.axiosGetFetcher(SETTING_GET_SETTING_PATH);
}

export const settingService = { getSetting, SETTING_GET_SETTING_PATH };
