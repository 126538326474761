import { Button } from '../../components';

export function AskRegister() {
  return (
    <div className="mb-4 md:mb-0 flex flex-col">
      <h2 className="mb-5 text-3xl">Je ne suis pas encore abonné.e</h2>
      <p className="text-lg">
        En créant votre compte, vous pourrez :
      </p>
      <ul className="mb-5 text-lg list-disc ml-5">
        <li>vous abonner</li>
        <li>consulter l'état de votre abonnement</li>
        <li>accéder à la lecture en ligne !</li>
      </ul>
      <div className="flex justify-center">
      <Button
        link={true}
        action="/inscription"
        text="Créer mon compte"
        className="px-10 py-4 text-lg uppercase"
      />
      </div>
    </div>
  );
}
