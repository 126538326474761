import Router from 'next/router'

import { BarLoader } from 'react-spinners'
import { AiOutlineUser, AiOutlineSearch } from 'react-icons/ai';
import { BsBasket } from 'react-icons/bs';
import { IoLogOutOutline } from 'react-icons/io5'

import { useRouter } from 'next/router';
import useSWR from 'swr';

import { settingService, authService } from '../../services';
import { axiosInstance } from '../../utils';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useAppContext } from '../../context';
import { Button } from '../Button';
import { Form, Input } from '../form';
import { Field } from 'react-final-form';

export function TopNav({ isLogged, cart }) {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const { addAlert, cartNb, setCartNb } = useAppContext();
  const [isLogout, setIsLogout] = useState(false);

  const { data } = useSWR(settingService.SETTING_GET_SETTING_PATH, axiosInstance.axiosGetFetcher);
  const router = useRouter();
  useEffect(() => {
    setCartNb(cart?.lines?.length ?? 0)
  }, [cart])

  useEffect(() => {
    Router.events.on("routeChangeStart", (url) => {
      setIsPageLoading(true)
    });
    Router.events.on("routeChangeComplete", (url) => {
      setIsPageLoading(false)
    });
    Router.events.on("routeChangeError", (url) => {
      setIsPageLoading(false)
    });
  }, [Router])

  const getUrlFromReglages = (reglageName) => {
    const reglagesFiltered = data.filter(reglage => reglage.name == reglageName)[0]
    return reglagesFiltered.item.presignedUrl;
  }

  const searchFunction = async (values) => {
    if (values?.search)
      router.push({
        pathname: '/librairie',
        query: {
          search: values.search
        }
      })
  };

  const submitLogout = async () => {
    try {
      await authService.callLogout();
      setIsLogout(false)
      router.push('/')
    } catch (err) {
      setIsLogout(false)
      addAlert({
        status: err?.response?.data?.status,
        msg: err?.response?.data?.msg,
      });
    }
  };

  return (
    <nav className="relative mb-5 lg:h-56">
      <div className='fixed top-0 z-50 w-full'>
        {isPageLoading && <BarLoader color="#D60A52" speed={0.4} cssOverride={{ width: '100%' }} />}
      </div>
      <div className="flex flex-col justify-center h-full py-5 mx-5 md:flex-row md:justify-between lg:mx-20 xl:mx-40">
        <Button
          color="none"
          link={true}
          action="/"
          className="h-[calc(100%_-_4rem)] w-full md:w-1/2 lg:w-auto self-center md:mr-5 relative"
          responsive={false}
        >
          <img
            className="max-h-full"
            src={getUrlFromReglages('img_primary')}
            alt={'Logo de l\'OCCE et la revue Animation et Education'}
          ></img>
        </Button>
        <div className="flex flex-col items-end mt-10 md:mt-0">
          <div className="flex flex-col w-full mb-6 sm:flex-row xs:w-auto sm:justify-end sm:items-center">
            <div className="mb-5 sm:mb-0 sm:mr-4">
              <Form
                onSubmit={searchFunction}
                formClass="relative"
              >
                <Field
                  component={Input}
                  type="text"
                  name="search"
                  classInput="border-blue-darker border-2 pr-12"
                  classContainer="w-full"
                />
                <Button
                  link={false}
                  rounded={false}
                  type="submit"
                  className="absolute -translate-y-1/2 bg-transparent hover:bg-transparent text-blue-darker hover:text-blue-dark top-1/2 right-1 w-fit"
                >
                  <AiOutlineSearch className="text-4xl" />
                </Button>
              </Form>
            </div>
            <div className="flex justify-center sm:ml-4 xs:justify-end">
              <Button
                color="none"
                link={true}
                action="/mon-espace"
                className="flex flex-col items-center mr-4 font-normal hover:text-blue-dark"
                responsive={false}
              >
                <AiOutlineUser className="mb-1 text-4xl" />
                <span className="text-sm">mon compte</span>
              </Button>

              <Button
                color="none"
                link={true}
                action="/panier"
                className={`flex flex-col items-center hover:text-blue-dark relative font-normal ${isLogged ? "mx-4" : "ml-4"}`}
                responsive={false}
              >
                <BsBasket className="mb-1 text-4xl" />
                <span className="text-sm">panier</span>
                {cartNb > 0 &&
                  <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white rounded-full bg-pink bottom-4 -right-2">
                    {cartNb}
                  </div>
                }
              </Button>
              {isLogged &&
                <Button
                  color="none"
                  link={false}
                  action={() => {
                    if (!isLogout) {
                      setIsLogout(true)
                      submitLogout()
                    }
                  }}
                  className="flex flex-col items-center ml-4 font-normal cursor-pointer hover:text-blue-dark"
                  responsive={false}
                >
                  <IoLogOutOutline className="mb-1 text-4xl" />
                  <span className="text-sm">déconnexion</span>
                </Button>
              }
            </div>
          </div>
          <div className="flex flex-col items-center mx-auto text-xl font-bold uppercase xs:flex-row xs:items-baseline xs:mx-0">
            {/* <Button
              color="orange"
              link={true}
              action="/abonnement"
              className="rounded-[15px] whitespace-nowrap px-4 py-2"
              responsive={false}
            >
              Je m'abonne
            </Button> */}
            <Button
              color="none"
              link={true}
              action="/librairie"
              className="px-4 py-2 mx-4 hover:text-blue-dark"
              responsive={false}
            >
              Librairie
            </Button>
            <a target="_blank" className="py-2 xs:pl-4 hover:text-blue-dark" href="http://www2.occe.coop/">
              Occe
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
