import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { BsCheck, BsX } from 'react-icons/bs';
import { composeValidators, requiredAndNot0 } from '../utils';
import { Button } from './Button';
import { Input } from './form';

export function Offer({
  title, price, subscription = false, btn = "Choisir",
  color, list = [], main = false, className = "", field = false,
  offer = null, selectOffer = null, action = "", disabled = false
}) {
  const [shadow, setShadow] = useState("shadow-[5px_5px_5px_var(--color-orange-dark),20px_20px_0_var(--color-orange)]")
  const [textColor, setTextColor] = useState("text-orange");
  const [bgColor, setBgColor] = useState("bg-orange");

  useEffect(() => {
    switch (color) {
      case "blue":
        setShadow("shadow-[5px_5px_5px_var(--color-blue-shadow),20px_20px_0_var(--color-blue)]")
        setTextColor("text-blue")
        setBgColor("bg-blue")
        break;
      case "pink":
        setShadow("shadow-[5px_5px_5px_var(--color-pink-dark),20px_20px_0_var(--color-pink)]")
        setTextColor("text-pink")
        setBgColor("bg-pink")
        break;
      case "green":
        setShadow("shadow-[5px_5px_5px_var(--color-green-shadow),20px_20px_0_var(--color-green)]")
        setTextColor("text-green")
        setBgColor("bg-green")
        break;
    }

  }, [])

  return (
    // w-52 h-96
    <div className={`
      relative ${className.includes("bg-") ? "" : "bg-white"} min-w-[11rem] m-1 h-[25rem]
      overflow-hidden flex flex-col justify-between pb-8 ${className}`}>
      <div className={`
        absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45
        rounded-[15%] w-[90%] xs:w-[120%] aspect-square ${shadow} bg-blue-darker z-10
      `}>
      </div>
      <div className="text-center z-20 w-full pb-[35%]">
        <h3 className={`leading-8 ${textColor} text-4xl z-10 mb-2 whitespace-pre pt-3 font-semibold`}>{title}</h3>
        {subscription ? (
          <span className="text-white text-3xl font-bold">
            {price}
            <span className="block text-xs font-normal">TTC par an</span>
          </span>
        ) : (
          <span className="text-white text-3xl font-semibold">
            {price}
            <sup className="text-base ml-2 font-normal">TTC</sup>
          </span>
        )}
      </div>
      {/* </div> */}
      {list && Array.isArray(list) &&
        <div className="flex items-center">
          <ul>
            {list.map((el, index) => (
              <li key={"item " + index} className="text-lg text-black flex items-center">
                {el[0] === true ? <BsCheck className="text-3xl text-lime-500" /> : <BsX className="text-3xl text-red-500" />}
                {el[1]}
              </li>
            ))}
          </ul>
        </div>
      }
      {field ?
        <div className="flex justify-center">
          <Field
            component={Input}
            type="radio"
            name="tarif"
            disabled={disabled}
            value={"" + offer.TARIF_NUM + ""}
            validate={composeValidators(requiredAndNot0)}
            onChange={e => selectOffer(e.target.value)}
          />
        </div>
        :
        <Button
          link={true}
          action={action}
          text={btn}
          color={color || "pink"}
          rounded={false}
          responsive={false}
          className={`${"Choisir" ? "px-8" : "px-4"} py-2 mx-auto uppercase shadow-lg text-xl`}
        />
      }
    </div>
  )
}
