import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Form, Input } from '../form';

import { Field } from 'react-final-form'
import {
  compose, composeValidators, email, maxLength, minLength, noSpace, password, required, requiredIfChange, sameValues
} from './../../utils';
import { customerService } from '../../../lib/services';
import { useAppContext } from '../../../lib/context';
import { Button } from '../Button';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export function EditPersonal({ initialValues, close }) {
  const { addAlert, getForm } = useAppContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const router = useRouter();
  const [values, setValues] = useState({});
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    getForm("edit-personal").subscribe(({ values }) => {
      setValues(values)
    }, { values: true })
  }, [])

  const submit = async (values) => {
    setIsProcessing(true)
    try {
      if (!executeRecaptcha) {
        addAlert({
          status: "error",
          msg: publicRuntimeConfig.ERROR_500
        })
        setIsProcessing(false);
        return;
      };
      const token = await executeRecaptcha();
      if (!token) {
        addAlert({
          status: "error",
          msg: publicRuntimeConfig.ERROR_500
        })
        setIsProcessing(false);
        return;
      }
      values.token = token;
      const data = await customerService.update(values.indivnum, values);
      if (data) addAlert(data)
      close()
      // authService.reloadUser();
      router.replace(router.asPath);
    } catch (err) {
      addAlert({
        status: err?.response?.data?.status !== 200 ? "error" : "success",
        msg: err?.response?.data?.msg
      })
    }
    setIsProcessing(false)
  }

  return (
    <Form
      formName="edit-personal"
      onSubmit={submit}
      initialValues={initialValues}
      validate={
        compose(
          requiredIfChange("email", initialValues.email, "password"),
          sameValues("newPassword", "newPasswordConfirm", "Nouveau mot de passe")
        )
      }
    >
      <div className="grid grid-cols-6 gap-4">
        <Field
          classContainer="col-span-6 sm:col-span-3 mt-5"
          component={Input}
          asterisk={true}
          validate={composeValidators(required, noSpace, email, maxLength(100))}
          label="Email"
          name="email"
          type="email"
        />
        <span className="col-span-3 hidden sm:block"></span>
        <Field
          classContainer="col-span-6 sm:col-span-3 mt-5"
          component={Input}
          validate={composeValidators(noSpace, password, minLength(8), maxLength(20))}
          asterisk={values.newPassword || values.newPasswordConfirm}
          passwordStrength={true}
          label="Nouveau mot de passe"
          name="newPassword"
          type="password"
        />
        <Field
          classContainer="col-span-6 sm:col-span-3 sm:mt-5"
          component={Input}
          validate={composeValidators(noSpace, minLength(8), maxLength(20))}
          asterisk={values.newPassword || values.newPasswordConfirm}
          label="Confirmer mot de passe"
          name="newPasswordConfirm"
          type="password"
        />
        <div className="col-span-6 sm:col-span-3 mt-5">
          <Field
            component={Input}
            infoInput="Requis si vous modifiez votre email ou mot de passe"
            asterisk={values.email !== initialValues.email || values.newPassword || values.newPasswordConfirm}
            label="Mot de passe actuel"
            name="password"
            type="password"
          />
        </div>
        <Button
          link={false}
          disabled={isProcessing}
          isLoading={isProcessing}
          type="submit"
          text="Enregistrer"
          className="px-10 py-3 text-lg h-fit self-end"
        />
      </div>
    </Form>
  );
}
