/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api/payment/systempay`;

async function createPayment(amount, currency, indivNum, orderId) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/createPayment`, {
    amount: amount,
    currency: currency,
    indivNum: indivNum,
    orderId: orderId
  });
}

export const systempayService = {
  createPayment,
};
