import { gql } from '@apollo/client';
import { query } from '../../utils';

async function addressValidationRules(countryCode = "FR") {
  try {
    const data = await query({
      query: gql`
        query AddressValidationRules($countryCode: CountryCode!) {
          addressValidationRules(countryCode: $countryCode) {
            postalCodeMatchers
            postalCodeExamples
          }
        }
      `,
      variables: {
        countryCode: countryCode,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    return data;
  } catch (err) {
    throw err;
  }
}

export const validationSaleor = {
  addressValidationRules
};
