/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api`;
async function complete(gateway, paymentData = null, token) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/order`, { gateway, paymentData, token });
}

export const orderService = {
  complete,
};
