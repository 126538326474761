import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import { getIssueTitle, getIssueContent, getIssueNumber, getSquareImg } from '../../utils';
import { Button } from '../Button';
import { SanitizeHTML } from '../SanitizeHTML';

export function LastIssue({ havePlus = false, lastIssue }) {
  return (
    <Fragment>
      <h2 className="text-5xl text-pink font-bold inline uppercase flex items-center mb-5">
        <span className="w-40 h-1 bg-pink hidden sm:block mr-10"></span>
        Le dernier numéro !
      </h2>
      <div className="flex items-center">
        <div className={`${havePlus ? "" : "md:mr-10"}`}>
          <div className={`flex md:flex-row flex-col mb-5 ${havePlus ? "items-center" : ""}`}>
            <Image 
              className={`
                ${havePlus ? 
                  "w-44 h-44 mr-10 object-cover " 
                  : "object-cover block md:hidden w-full"} aspect-square
              `}
              src={getSquareImg(lastIssue) ?? "/imgs/AE_PhotoCooperationScolaire.png"}
              alt={"image présentant le dernier numéro d'Animation et Éducation"}
              sizes="175px"
              width={175}
              height={175}
            />
            <h3 className="text-5xl italic">{getIssueTitle(lastIssue)}</h3>
          </div>
          <SanitizeHTML html={getIssueContent(lastIssue, havePlus)} />
          <Button 
            link={true}
            action={`/librairie/${getIssueNumber(lastIssue)}`}
            text="Lire la suite"
            color="pink"
            plain={false}
            className="px-20 py-4 text-xl mt-8"
          />
        </div>
        {!havePlus && 
          <Image 
            className="object-cover hidden md:block h-full aspect-square w-1/3"
            src={getSquareImg(lastIssue) ?? "/imgs/AE_PhotoCooperationScolaire.png"}
            alt={"image présentant le dernier numéro d'Animation et Éducation"}
            sizes="350px"
            width={350}
            height={175}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        }
      </div>
    </Fragment>
  );
}
