import { displayRecipient } from "../../utils";

export function CheckoutProduct({ name, img, quantity, price, currency, passedKey, line, user }) {
  return (
    <div className="grid grid-cols-12 gap-4" key={passedKey}>
      <div className="col-span-2 md:col-span-3 xl:col-span-3 2xl:col-span-2">
        <img className="h-auto md:max-w-full" src={img?.url} alt={img?.alt}></img>
      </div>
      <div className="col-span-10 md:col-span-9 xl:col-span-9 2xl:col-span-10 flex flex-col justify-between space-y-4">
        <h6 className="strong text-md">{name}</h6>
        {displayRecipient(line, user)}
        <div className="flex flex-row justify-between items-center text-smm">
          <p>Quantité : {quantity}</p>
          <p>
            {price.gross.amount} {currency}
          </p>
        </div>
      </div>
    </div>
  );
}
