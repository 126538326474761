/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api`;

async function getLePlus(limit = 10, offset = 0, search = "", token) {
  return await axiosInstance.axiosGetFetcher(`${baseUrl}/le-plus?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ''}&token=${token}`);
}

export const leplusService = {
  getLePlus
};
