import { useEffect, useState, Fragment } from "react";
import { AiFillExclamationCircle, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { BsAsterisk } from "react-icons/bs"
import { ToolTip } from "../ToolTip";

export function Input({
  input: {type = null, ...input}, onChange, meta = {}, label = "", classContainer = "", classInput = "",
  asterisk = false, action = null, passwordStrength = false, infoInput = null, ...rest
}) {
  const [ strength, setStrength ] = useState({
    level: 0,
    lib: "Trop faible"
  });

  const [ reveal, setReveal ] = useState(false);
  const toggleReveal = () => {
    if (type === "password") {
      setReveal(!reveal)
    }
  }
  
  // useEffect(() => {
  //   if (passwordStrength) {
  //     calculStrength();
  //   }
  // }, [input?.value])

  const calculStrength = () => {
    const _value = input.value.trim()
    const _strength = {
      level: 0,
      lib: "Trop faible"
    }
    if (_value.length < 8) {
      _strength.level = 0
      _strength.lib = "Trop court"
      return setStrength(_strength);
    } else {
      _strength.level = 1
    }

    const letterLength = _value.replace(/[^a-zA-Z]/g, '').length;
    const lowerLength = _value.replace(/[^a-z]/g, '').length;
    const upperLength = _value.replace(/[^A-Z]/g, '').length;
    const numberLength =  _value.replace(/[^0-9]/g, '').length;
    const specialLength =  _value.replace(/[^@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?!]/g, '').length;
    if (upperLength >= 3 && lowerLength >= 3) {
      _strength.level++;
    }
    if (numberLength >= 3 && letterLength >= 4) {
      _strength.level++;
    }
    if (specialLength >= 1 && letterLength >= 3) {
      _strength.level++;
    }

    switch(_strength.level) {
      case 0:
        _strength.lib = "Non sécurisé"
        break;
      case 1:
        _strength.lib = "Trop simple"
        break;
      case 2:
        _strength.lib = "Moyen"
        break;
      case 3:
        _strength.lib = "Bon"
        break;
      case 4:
        _strength.lib = "Fort"
        break;
    }
    return setStrength(_strength);
  }

  const valueReturned = (
    <label className={
      "text-lg font-semibold " + (type === "checkbox" || type === "radio" ? "flex flex-row-reverse justify-end items-center flex-wrap " : "") + classContainer
    }>
      {action ?
        <Fragment>
          {label}
          {asterisk && <BsAsterisk className="inline text-xxs align-super text-pink " />}
          {action}
        </Fragment>
      :
        <Fragment>
          {label}
          {asterisk && <BsAsterisk className="inline text-xxs align-super text-pink " />}
        </Fragment>
      }
      <div className="flex items-center">
        <input 
          {...input}
          {...rest}
          type={type === "password" && reveal ? "text" : type}
          onKeyDown={(e) => {
            if ((type === "password" || type === "email") && e.code === 'Space') e.preventDefault()
          }}
          onInput={(e) => {
            if (type === "email" && input.value && input.value === e.target.value) {
              if (e.target?.value) e.target.value = e.target.value.trim()
              input?.onChange(e.target?.value)
            }
          }}
          onChange={(e) => {
            //TODO: trim space after validation to let user have space in name or firstname
            if (type === "checkbox") {
              input?.onChange(e.target.checked)
            } else if (type === "email") {
              if (e.target?.value) e.target.value = e.target.value.trim()
              input?.onChange(e.target?.value)
            } else {
              input?.onChange(e.target?.value.trimStart()/*?.trim()*/)
            }
            if (onChange) onChange(e);
          }}
          className={`
            h-10 bg-gray-50 border block w-full px-2 py-1 font-normal text-black
            disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-100 `
            + (classInput.includes("rounded-") ? "" : " rounded-md ")
            + classInput
            + (meta.touched && meta.error ? " border-pink bg-pink-light opacity-40 " : (classInput.includes("border-blue") ? "" : "border-gray-300"))
            + (type === "checkbox" || type === "radio" ? ` w-4 ${label ? "mr-2" : ""} ` : "")
            + (type === "password" ? ` pr-10 ` : "")
          }
        />
        {type === "password" &&
          <span className="-ml-8 cursor-pointer z-10" onClick={toggleReveal}>
            {reveal ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </span>
        }
      </div>
      {infoInput && 
        <p className="text-sm font-normal text-gray-500">{infoInput}</p>
      }
      {meta.touched && meta.error && 
        <span className={"flex items-center mb-3 text-pink font-normal " + (type === "checkbox" || type === "radio" ? "w-full" : "")}>
          <AiFillExclamationCircle className="mr-1" />{meta.error}
        </span>
      }
    </label>
  );

  if (passwordStrength) {
    return (
      <ToolTip 
        content={(
          <div className="text-base font-normal">
            <p className="font-bold">
              Votre mot de passe doit remplir tous les critères suivants :
            </p>
            <ul className="list-disc ml-5 mt-4">
              <li>minimum 8 caractères</li>
              <li>minimum 1 majuscule</li>
              <li>minimum 1 minuscule</li>
              <li>minimum 1 chiffre</li>
              <li>minimum 1 caractère spécial suivants : # @ ! - _ * . ?</li>
            </ul>
          </div>
        )}
        position="top"
      >
        {valueReturned}
      </ToolTip>
    )
  }
  return valueReturned;
}
