import { GraphQLError } from 'graphql';
import { ApolloClient, InMemoryCache, HttpLink, from, gql, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import getConfig from 'next/config';
import { authService } from './lib/services';

const { serverRuntimeConfig } = getConfig();

let _context;
const selectRefresh = () => {
  // if (typeof window !== "undefined") console.error("CLIENT SIDE APOLLO REFRESH ALARM !")

  const refresh = authService.getRefresh(_context?.req ?? null, _context?.res ?? null);
  return refresh ?? null;
}

const resetToken = (token) => {
  authService.setToken(token, _context.req, _context.res);
}

export const setGraphContext = (req, res) => {
  _context = { req, res }
};

const httpLink = new HttpLink({
  uri: `${serverRuntimeConfig.GRAPHQL_URL}/graphql/`,
  fetch: (...pl) => {
    const [_, options] = pl
    const body = JSON.parse(options.body)
    console.log(`📡${body.operationName || ''}`)
    // console.log(`📡${body.operationName || ''}\n${body.query}`, body.variables)
    return fetch(...pl)
  }
});

const authLink = setContext((_, { headers, noAuth = false, app = false }) => {
  // if (typeof window !== "undefined") console.error("CLIENT SIDE APOLLO ALARM !")

  if (!headers) headers = {}
  // Header if saleor request with app TOKEN
  if (app && serverRuntimeConfig.SALEOR_APP_KEY) {
    // console.error("SALEOR_APP_KEY ALARM !")
    headers.Authorization = `Bearer ${serverRuntimeConfig.SALEOR_APP_KEY}`
    return { headers }
  }
  // No TOKEN in header (for refreshToken)
  if (noAuth) {
    return { headers }
  }
  // Select TOKEN if client or server
  const token = authService.getToken(_context?.req ?? null, _context?.res ?? null) ?? null;
  if (token) {
    headers.Authorization = `Bearer ${token}`
    return { headers }
  }
  return { headers }
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.exception.code) {
        case 'InvalidTokenError':
        case 'InvalidSignatureError':
          return new Observable((observer) => {
            (async () => {
              try {
                authService.logout(_context.req, _context.res)
                return observer.next()
              } catch (err) {
                observer.error(err);
              }
            })();
          });
        case 'ExpiredSignatureError':
          return new Observable((observer) => {
            (async () => {
              try {
                //TODO: logout when no refresh available
                if (operation.operationName === 'TokenRefresh' || !selectRefresh()) {
                  authService.logout(_context.req, _context.res)
                  return observer.next()
                };

                const accessToken = await refreshToken();
                if (!accessToken) {
                  authService.logout(_context.req, _context.res)
                  return observer.next()
                }
                resetToken(accessToken);

                // Retry the failed request
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              } catch (err) {
                observer.error(err);
              }
            })();
          });
      }

    }
  }
});

const refreshToken = async () => {
  try {
    const { data } = await client.mutate({
      mutation: gql`
        mutation TokenRefresh($refreshToken: String) {
          tokenRefresh(refreshToken: $refreshToken) {
            token
            errors {
              message
              code
            }
          }
        }
      `,
      variables: {
        refreshToken: selectRefresh(),
      },
      context: {
        noAuth: true
      },
    });
    const { token } = data?.tokenRefresh;
    return token;
  } catch (err) {
    throw err;
  }
};

const client = new ApolloClient({
  ssrMode: true,
  link: from([errorLink, authLink, httpLink]), // Chain it with the HttpLink
  cache: new InMemoryCache(),
});

export default client;
