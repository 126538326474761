
import getConfig from 'next/config';
import { gql } from '@apollo/client';
import { query } from '../../utils';

const { serverRuntimeConfig } = getConfig();

const PRODUCT_QUERY = `
  id
  variants {
    id
    pricing {
      onSale
      priceUndiscounted {
        currency
        gross {
          currency
          amount
        }
      }
      discount {
        currency
        gross {
          currency
          amount
        }
      }
      price {
        currency
        gross {
          currency
          amount
        }
      }
    }
  }
  thumbnail(size: 400) {
    url
    alt
  }
  attributes {
    attribute {
      name
      slug
    }
    values {
      name
      richText
      file {
        url
      }
    }
  }
`;

const SINGLE_PRODUCT_QUERY = PRODUCT_QUERY.replace("size: 400", "size: 1000")

async function getIssue(num, noCache = false) {
  try {
    const productByNum = await query({
      query: gql`
        query Products($filter: ProductFilterInput, $first: Int, $channel: String) {
          products(filter: $filter, first: $first, channel: $channel) {
            edges {
              node {
                id
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          isPublished: true,
          search: `${num} animation`
        },
        first: 1,
        channel: serverRuntimeConfig.DEFAULT_CHANNEL_SLUG
      },
      fetchPolicy: !noCache ? 'network-only' : 'no-cache',
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    if (productByNum && productByNum.edges?.[0]?.node?.id) {
      const id = productByNum.edges?.[0]?.node?.id;
      if (!id) {
        return null;
      }
      const data = await query({
        query: gql`
          query Product($id: ID, $channel: String) {
            product(id: $id, channel: $channel) { 
              ${SINGLE_PRODUCT_QUERY}
            }
          }
        `,
        variables: {
          id,
          channel: serverRuntimeConfig.DEFAULT_CHANNEL_SLUG
        },
        fetchPolicy: !noCache ? 'network-only' : 'no-cache',
        throwError: true,
      }).catch((err) => {
        throw err;
      });
      return data;
    }

    return null;
  } catch (err) {
    throw err;
  }
}

async function getIssues(action = "next", cursor = null, nbIssue = 10, search, noCache = false) {
  try {
    const types = await query({
      query: gql`
        query ProductTypes($filter: ProductTypeFilterInput, $first: Int) {
          productTypes(filter: $filter, first: $first) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                availableAttributes(first: 10) {
                  edges {
                    node {
                      id
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          slugs: ["animation-education"]
        },
        first: 1,
      },
      context: {
        app: true,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    if (types && types.edges?.[0]?.node?.id) {
      const attr = types.edges?.[0]?.node?.availableAttributes?.edges?.find(x => x.node.slug === 'order')?.node;
      if (!attr) {
        return null;
      }
      const variables = {
        filter: {
          isPublished: true,
          productTypes: [
            types.edges?.[0]?.node?.id
          ]
        },
        sortBy: {
          direction: "DESC",
          attributeId: attr.id
        },
        channel: serverRuntimeConfig.DEFAULT_CHANNEL_SLUG
      };
      if (action && !cursor && ["next", "prev"].includes(action)) {
        variables[action === "next" ? "first" : "last"] = nbIssue
      }
      if (action && cursor && ["next", "prev"].includes(action)) {
        variables[action === "next" ? "first" : "last"] = nbIssue
        variables[action === "next" ? "after" : "before"] = cursor
      }
      if (search) {
        variables.filter["search"] = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        delete variables.sortBy.attributeId
        variables.sortBy["field"] = "NAME"
      }
      
      const data = await query({
        query: gql`
          query Products($filter: ProductFilterInput, $first: Int, $last: Int, $sortBy: ProductOrder, $after: String, $before: String, $channel: String) {
            products(filter: $filter, first: $first, last: $last, sortBy: $sortBy, after: $after, before: $before, channel: $channel) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              totalCount
              edges {
                node {
                  ${PRODUCT_QUERY}
                }
              }
            }
          }
        `,
        variables,
        fetchPolicy: !noCache ? 'network-only' : 'no-cache',
        throwError: true,
      }).catch((err) => {
        throw err;
      });
      return data;
    }

    return null;
  } catch (err) {
    throw err;
  }
}

async function getLastIssue(noCache = false) {
  try {
    const types = await query({
      query: gql`
        query ProductTypes($filter: ProductTypeFilterInput, $first: Int) {
          productTypes(filter: $filter, first: $first) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                availableAttributes(first: 10) {
                  edges {
                    node {
                      id
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          slugs: ["animation-education"]
        },
        first: 1,
      },
      context: {
        app: true,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    if (types && types.edges?.[0]?.node?.id) {
      const attr = types.edges?.[0]?.node?.availableAttributes?.edges?.find(x => x.node.slug === 'order')?.node;
      if (!attr) {
        return null;
      }
      const variables = {
        filter: {
          isPublished: true,
          productTypes: [
            types.edges?.[0]?.node?.id
          ]
        },
        sortBy: {
          direction: "DESC",
          attributeId: attr.id
        },
        first: 1,
        channel: serverRuntimeConfig.DEFAULT_CHANNEL_SLUG
      };
      const data = await query({
        query: gql`
          query Products($filter: ProductFilterInput, $first: Int, $sortBy: ProductOrder, $after: String, $channel: String) {
            products(filter: $filter, first: $first, sortBy: $sortBy, after: $after, channel: $channel) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                endCursor
              }
              totalCount
              edges {
                node {
                  ${PRODUCT_QUERY}
                }
              }
            }
          }
        `,
        variables,
        fetchPolicy: !noCache ? 'network-only' : 'no-cache',
        throwError: true,
      }).catch((err) => {
        throw err;
      });
      return data?.edges?.[0]?.node ?? null;
    }

    return null;
  } catch (err) {
    throw err;
  }
}

export const issueSaleor = {
  getIssue, getIssues, getLastIssue
};
