const contentToHtml = (content, resume = false) => {
  try {
    let html = ""
    content = JSON.parse(content)
    if (!content.blocks || content.blocks.length === 0) return ""
    if (resume) content.blocks.length = 1

    content.blocks.forEach(block => {
      switch (block.type) {
        case "header":
          html += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`
          break;
        case "paragraph":
          html += `<p>${block.data.text}</p>`
          break;
        case "list":
          html += `<${block.data.style === "ordered" ? "ol" : "ul"}>`
          block.data.items.forEach(item => {
            html += `<li>${item}</li>`;
          })
          html += `</${block.data.style === "ordered" ? "ol" : "ul"}>`
          break;
        case "quote":
          html += `<p>`
          html += `<q cite="${block.data.caption}">${block.data.text}</q>`
          html += `, ${block.data.caption}`
          html += `</p>`
          break;
      }
    });
    return html;
  } catch {
    return ""
  }
}

export const getSquareImg = (product) => {
  if (product.node && product.node.attributes && product.node.attributes.length > 0) {
    return product.node.attributes.find(x => x?.attribute?.slug === "square-img")?.values?.[0]?.file?.url
  }
  if (product.attributes && product.attributes.length > 0) {
    return product.attributes.find(x => x?.attribute?.slug === "square-img")?.values?.[0]?.file?.url
  }
  return "";
}

export const getIssueTitle = (product) => {
  if (product.node && product.node.attributes && product.node.attributes.length > 0) {
    return product.node.attributes.find(x => x?.attribute?.slug === "featured-title")?.values?.[0]?.name
  }
  if (product.attributes && product.attributes.length > 0) {
    return product.attributes.find(x => x?.attribute?.slug === "featured-title")?.values?.[0]?.name
  }
  return "";
}

export const getIssueContent = (product, resume = false) => {
  if (product.node && product.node.attributes && product.node.attributes.length > 0) {
    const contentJson = product.node.attributes.find(x => x?.attribute?.slug === "featured-content")?.values?.[0]?.richText
    return contentToHtml(contentJson, resume);
  }
  if (product.attributes && product.attributes.length > 0) {
    const contentJson = product.attributes.find(x => x?.attribute?.slug === "featured-content")?.values?.[0]?.richText
    return contentToHtml(contentJson, resume);
  }
  return "";
}

export const getSummaryContents = (product) => {
  if (product.node && product.node.attributes && product.node.attributes.length > 0) {
    const contentJson = product.node.attributes.find(x => x?.attribute?.slug === "contents")?.values?.[0]?.richText
    return contentToHtml(contentJson);
  }
  if (product.attributes && product.attributes.length > 0) {
    const contentJson = product.attributes.find(x => x?.attribute?.slug === "contents")?.values?.[0]?.richText
    return contentToHtml(contentJson);
  }
  return "";
}

export const getSummaryContentsHs = (product) => {
  if (product.node && product.node.attributes && product.node.attributes.length > 0) {
    const contentJson = product.node.attributes.find(x => x?.attribute?.slug === "contents-hs")?.values?.[0]?.richText
    return contentToHtml(contentJson);
  }
  if (product.attributes && product.attributes.length > 0) {
    const contentJson = product.attributes.find(x => x?.attribute?.slug === "contents-hs")?.values?.[0]?.richText
    return contentToHtml(contentJson);
  }
  return "";
}

export const getIssueNumber = (product) => {
  if (product.node && product.node.attributes && product.node.attributes.length > 0) {
    return product.node.attributes.find(x => x?.attribute?.slug === "issue")?.values?.[0]?.name
  }
  if (product.attributes && product.attributes.length > 0) {
    return product.attributes.find(x => x?.attribute?.slug === "issue")?.values?.[0]?.name
  }
  return "";
}

export const getIssueExtract = (product) => {
  if (product.node && product.node.attributes && product.node.attributes.length > 0) {
    return product.node.attributes.find(x => x?.attribute?.slug === "free-extract")?.values?.[0]?.name
  }
  if (product.attributes && product.attributes.length > 0) {
    return product.attributes.find(x => x?.attribute?.slug === "free-extract")?.values?.[0]?.name
  }
  return "";
}