import { Fragment, useEffect, useState } from 'react';
import { Field, useForm } from 'react-final-form';

import { composeValidators, maxLength, regex, required, selectState } from '../../utils';
import { Button } from '../Button';
import { Form, Input, Select } from '../form';

import { useAppContext } from '../../context';
import { validationService } from '../../services';

export function CreateWithAddress({justAdr = false, shadow = true, school = false, countryBlock = false, values}) {
  const [ rules, setRules ] = useState(false);
  const form = useForm();

  const validationRules = async () => {
    try {
      const data = await validationService.getAddressRules(values.country);
      setRules(data)
    } catch (err) {
      setRules({})
    }
  }

  useEffect(() => {
    validationRules()
  }, [])

  return (
    <Fragment>
      <div className={"mb-4 md:mb-0 flex flex-col " + (shadow ? "shadow-xl" : "") + (justAdr ? "" : "p-3")}>
        {!justAdr && <h2 className="mb-5 font-bold text-xl">Informations personnelles</h2>}
        <div className="grid grid-cols-8 gap-x-4 gap-y-2">
          <Field
            classContainer="col-span-4 sm:col-span-3 md:col-span-1"
            component={Input}
            label="Civ"
            type="text"
            name="civ"
          />
          <span className="block md:hidden col-span-4"></span>
          <Field
            classContainer="col-span-8 sm:col-span-4 md:col-span-3"
            component={Input}
            validate={composeValidators(required, maxLength(30))}
            asterisk={true}
            label="Prénom"
            type="text"
            name="firstname"
          />
          <Field
            classContainer="col-span-8 sm:col-span-4 md:col-span-3 whitespace-nowrap"
            component={Input}
            validate={composeValidators(required, maxLength(30))}
            asterisk={true}
            label={school ? "Nom ou Établissement Scolaire" : "Nom"}
            type="text"
            name="lastname"
          />
          {!justAdr &&
            <Fragment>
              <span className="hidden md:block"></span>
              <Field
                classContainer="col-span-8 sm:col-span-4 md:col-span-3"
                component={Input}
                validate={composeValidators(required, maxLength(100))}
                asterisk={true}
                label="Adresse email"
                type="email"
                name="email"
              />
              <span className="hidden sm:block sm:col-span-4 md:col-span-5"></span>
              <Field
                classContainer="col-span-8 sm:col-span-4 md:col-span-3"
                component={Input}
                label="Date de naissance"
                type="text"
                name="birthday"
              />
            </Fragment>
          }
        </div>
      </div>
      <div className={"mb-4 md:mb-0 flex flex-col" + (shadow ? "shadow-xl" : "") + (justAdr ? "" : "p-3")}>
        {!justAdr && <h2 className="mb-5 font-bold text-xl">Coordonnées</h2>}
        <div className="grid grid-cols-8 gap-x-4 gap-y-2">
          <Field
            classContainer="col-span-8 sm:col-span-5 md:col-span-3 lg:col-span-2"
            component={Input}
            validate={maxLength(30)}
            label="Société"
            type="text"
            name="company"
          />
          <span className="hidden sm:block col-span-3 md:col-span-5 lg:col-span-6"></span>
          <Field
            classContainer="col-span-8 sm:col-span-7 lg:col-span-6"
            component={Input}
            validate={composeValidators(required, maxLength(38))}
            asterisk={true}
            label="Adresse"
            type="text"
            name="address1"
          />
          <Field
            classContainer="col-span-8 sm:col-span-7 lg:col-span-6"
            component={Input}
            validate={maxLength(38)}
            type="text"
            name="address2"
          />
          <span className="hidden sm:block sm:col-span-1 lg:col-span-2"></span>
          <Field
            classContainer="col-span-8 sm:col-span-3 lg:col-span-2"
            component={Input}
            validate={composeValidators(required, maxLength(15), regex(rules.postalCodeMatchers, rules.postalCodeExamples))}
            asterisk={true}
            label="Code postal"
            type="text"
            name="postcode"
            onChange={e => selectState(e, form, "state")}
          />
          <Field
            classContainer="col-span-8 sm:col-span-3 lg:col-span-2"
            component={Input}
            validate={composeValidators(required, maxLength(38))}
            asterisk={true}
            label="Ville"
            type="text"
            name="city"
          />
          <span className="hidden sm:block sm:col-span-2 lg:col-span-4"></span>
          <Field
            classContainer="col-span-8 sm:col-span-3 lg:col-span-2"
            component={Select}
            validate={required}
            list={["country"]}
            disabled={countryBlock}
            asterisk={true}
            label="Pays"
            name="country"
            onChange={() => form.mutators.setValue("state", null)}
          />
          <Field
            classContainer="col-span-8 sm:col-span-3 lg:col-span-2"
            component={Select}
            validate={values?.["state-options"] ? required : null}
            country={values.country}
            list={["departement"]}
            hideIfEmpty={true}
            asterisk={values?.["state-options"]}
            label="Département"
            type="text"
            name="state"
            onChange={e => {
              if (!values.postcode?.startsWith(e.target.value) && values.country === "FR") {
                getForm("edit-address").mutators.setValue("postcode", e.target.value)
              }
            }}
          />
          <span className="hidden sm:block sm:col-span-2 lg:col-span-4"></span>
          <Field
            classContainer="col-span-8 sm:col-span-4 md:col-span-3 lg:col-span-2"
            component={Input}
            validate={maxLength(20)}
            label="Téléphone"
            type="phone"
            name="phone"
          />
          {justAdr &&
            <Field
              classContainer="col-span-8 sm:col-span-4 md:col-span-3"
              component={Input}
              validate={composeValidators(maxLength(100))}
              label="Adresse email"
              type="email"
              name="email"
            />
          }
        </div>
      </div>
    </Fragment>
  );
}