import { gql } from '@apollo/client';
import { init, query, mutation } from '../../utils';

// function initClient(token = null, refresh = null) {
//   init(token, refresh);
// }

async function tokenCreate(email, password) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation TokenCreate($email: String!, $password: String!) {
          tokenCreate(email: $email, password: $password) {
            user {
              id
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
              addresses {
                id
              }
            }
            token
            refreshToken
            csrfToken
            errors {
              message
            }
          }
        }
      `,
      variables: { email, password },
      throwError: false,
    });

    return data;
  } catch (err) {
    throw err;
  }
}

async function searchCustomerByIndivNum(indivNum, app = false) {
  try {
    const data = await query({
      query: gql`
        query Customers($filter: CustomerFilterInput) {
          customers(filter: $filter, first: 10) {
            totalCount
            edges {
              node {
                id
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          metadata: [{ key: 'INDIV_NUM', value: indivNum }],
        },
      },
      context: {
        app,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function customerUpdate(id, email, app = false) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation CustomerUpdate($id: ID!, $input: CustomerInput!) {
          customerUpdate(id: $id, input: $input) {
            user {
              id
              email
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
              addresses {
                id
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id,
        input: {
          email: email
        }
      },
      context: {
        app,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function customerUpdatePassword(userId, newPassword, app = false) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation CustomerUpdatePassword($userId: ID!, $newPassword: String!) {
          customerUpdatePassword(userId: $userId, newPassword: $newPassword) {
            user {
              id
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
              addresses {
                id
              }
            }
          }
        }
      `,
      variables: {
        userId,
        newPassword,
      },
      context: {
        app,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function accountRegister(
  firstName,
  lastName,
  email,
  password,
  languageCode = 'FR',
  indivNum,
  dob = "",
  prefix = "",
  region = ""
) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation AccountRegister($input: AccountRegisterInput!) {
          accountRegister(input: $input) {
            user {
              id
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        input: {
          firstName: firstName,
          lastName: lastName,
          languageCode: languageCode,
          email: email,
          password: password,
          metadata: [
            {
              key: 'INDIV_NUM',
              value: indivNum,
            },
            {
              key: 'DOB',
              value: dob ?? "",
            },
            {
              key: 'PREFIX',
              value: prefix ?? "",
            },
            {
              key: 'REGION',
              value: region ?? "",
            }
          ],
        },
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function requestEmailChange(
  newEmail,
  password,
  //TODO: Changer la valeur par une dynamique
  redirectUrl
) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation RequestEmailChange($newEmail: String!, $password: String!, $redirectUrl: String!) {
          requestEmailChange(newEmail: $newEmail, password: $password, redirectUrl: $redirectUrl) {
            user {
              id
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        newEmail,
        password,
        redirectUrl
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function confirmEmailChange(token) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation ConfirmEmailChange($token: String!) {
          confirmEmailChange(token: $token) {
            user {
              id
              email
              metadata {
                key
                value
              }
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        token,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function passwordChange(
  newPassword,
  oldPassword
) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation PasswordChange($newPassword: String!, $oldPassword: String!) {
          passwordChange(newPassword: $newPassword, oldPassword: $oldPassword) {
            user {
              id
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        newPassword,
        oldPassword
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function accountUpdate(
  firstName,
  lastName
) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation AccountUpdate($input: AccountInput!) {
          accountUpdate(input: $input) {
            user {
              id
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        input: {
          firstName: firstName,
          lastName: lastName
        },
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function updateMetadata(
  userId,
  indivNum,
  dob = "",
  prefix = "",
  region = "",
  app = false
) {
  try {
    const input = []
    if (indivNum || indivNum === "") input.push({ key: "INDIV_NUM", value: indivNum })
    if (dob || dob === null || dob === "") input.push({ key: "DOB", value: dob ?? "" })
    if (prefix || prefix === "") input.push({ key: "PREFIX", value: prefix })
    if (region || region === null || region === "") input.push({ key: "REGION", value: region ?? "" })
    const data = await mutation({
      mutation: gql`
        mutation UpdateMetadata($id: ID!, $input: [MetadataInput!]!) {
          updateMetadata(id: $id, input: $input) {
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id: userId,
        input,
      },
      context: {
        app,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function accountAddressCreate(adr, type = 'SHIPPING') {
  try {
    const data = await mutation({
      mutation: gql`
        mutation AccountAddressCreate($input: AddressInput!, $adrType: AddressTypeEnum) {
          accountAddressCreate(input: $input, type: $adrType) {
            user {
              id
              defaultShippingAddress {
                id
              }
              defaultBillingAddress {
                id
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        input: {
          firstName: adr.PRENOM_NUM,
          lastName: adr.NOM_NUM,
          companyName: adr.ADR_COMP1?.trim() || '',
          streetAddress1: adr.ADR_ADR?.trim(),
          streetAddress2: adr.ADR_COMP2?.trim() || '',
          city: adr.ADR_VILLE?.trim(),
          // cityArea: "",
          postalCode: adr.ADR_CP?.trim(),
          country: adr.code?.trim(),
          countryArea: adr.ADR_ETAT?.trim() || '',
          phone: adr.ADR_TEL?.trim() || '',
        },
        adrType: type,
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function accountAddressDelete(idAdr) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation AccountAddressDelete($adrId: ID!) {
          accountAddressDelete(id: $adrId) {
            errors {
              field
              message
            }
          }
        }
      `,
      variables: { adrId: idAdr },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function accountAddressUpdate(
  id, firstName, lastName, companyName, streetAddress1,
  streetAddress2, city, postalCode, country, countryArea, phone
) {
  try {
    const input = {};
    if (firstName) input.firstName = firstName
    if (lastName) input.lastName = lastName
    if (companyName) input.companyName = companyName
    if (streetAddress1) input.streetAddress1 = streetAddress1
    if (streetAddress2) input.streetAddress2 = streetAddress2
    if (city) input.city = city
    if (postalCode) input.postalCode = postalCode
    if (country) input.country = country
    if (countryArea) input.countryArea = countryArea
    if (phone) input.phone = phone

    const data = await mutation({
      mutation: gql`
        mutation AccountAddressUpdate($id: ID!, $input: AddressInput!) {
          accountAddressUpdate(id: $id, input: $input) {
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        id,
        input: input
      },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}


async function me(noCache = false) {
  try {
    const data = await query({
      query: gql`
        query Me {
          me {
            id
            firstName
            lastName
            email
            metadata {
              key
              value
            }
            checkouts (first: 1) {
              edges {
                node {
                  id
                  lastChange
                  lines {
                    id
                    variant {
                      id
                    }
                  }
                }
              }
            }
            addresses {
              id
              firstName
              lastName
              streetAddress1
              streetAddress2
              companyName
              city
              cityArea
              postalCode
              country {
                code
                country
              }
              countryArea
              phone
            }
            defaultShippingAddress {
              firstName
              lastName
              streetAddress1
              streetAddress2
              companyName
              city
              cityArea
              postalCode
              country {
                code
                country
              }
              countryArea
              phone
            }
            defaultBillingAddress {
              firstName
              lastName
              streetAddress1
              streetAddress2
              companyName
              city
              cityArea
              postalCode
              country {
                code
                country
              }
              countryArea
              phone
            }
          }
        }
      `,
      // fetchPolicy: !noCache ? 'network-only' : 'no-cache',
      //TODO: on no-cache tout le temps pour être sûr d'avoir la dernière version
      fetchPolicy: 'no-cache',
      throwError: true
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function requestPasswordReset(email, domain) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation RequestPasswordReset($email: String!, $redirectUrl: String!) {
          requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        email,
        redirectUrl: domain + "/password/recovery"
      },
      throwError: true
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function setPassword(email, password, token) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation SetPassword($email: String!, $password: String!, $token: String!) {
          setPassword(email: $email, password: $password, token: $token) {
            token
            refreshToken
            errors {
              field
              message
            }
          }
        }
      `,
      variables: {
        email,
        password,
        token
      },
      throwError: true
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function verifyToken(token) {
  try {
    const data = await mutation({
      mutation: gql`
        mutation TokenVerify($token: String!) {
          tokenVerify(token: $token) {
            user {
              id
            }
            isValid
            errors {
              field
              message
            }
          }
        }
      `,
      variables: { token },
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function tokensDeactivateAll() {
  try {
    const data = await mutation({
      mutation: gql`
        mutation TokensDeactivateAll {
          tokensDeactivateAll {
            errors {
              field
              message
            }
          }
        }
      `,
      throwError: true,
    }).catch((err) => {
      throw err;
    });
    return data;
  } catch (err) {
    throw err;
  }
}

export const customerSaleor = {
  // initClient,
  tokenCreate,
  searchCustomerByIndivNum,
  customerUpdate,
  customerUpdatePassword,
  requestEmailChange,
  confirmEmailChange,
  passwordChange,
  accountUpdate,
  updateMetadata,
  accountRegister,
  accountAddressCreate,
  accountAddressDelete,
  accountAddressUpdate,
  verifyToken,
  tokensDeactivateAll,
  me,
  requestPasswordReset,
  setPassword
};
