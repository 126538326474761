import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
const baseUrl = `${serverRuntimeConfig.API_PRESSE_URL}/newsletter`;

import { axiosInstance } from '../../utils';

const updateNewsletterSubscription = async (indivNum, data) => {
  try {
    if (typeof window !== 'undefined') return;
    return await axiosInstance.axiosPutFetcher(`${baseUrl}/individu/${indivNum}`, data);
  } catch (err) {
    throw err;
  }
};

export const newsletterPresseService = { updateNewsletterSubscription };
