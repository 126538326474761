import { composeValidators, required, requiredAndNot0 } from "../../utils";
import { Input } from "../form";
import { Field } from 'react-final-form';

export function Renew({ values, userSubs, addAboNum }) {
  return (
    <div className="w-full mt-10  transition-height ease-in-out duration-300">
      <h2 className="font-bold text-pink text-xl mb-3">
        Voulez-vous renouveler {userSubs && userSubs.length > 1 ? "un de vos abonnements" : "votre abonnement"} ?
      </h2>
      <div className="flex">
        <Field
          component={Input}
          label="oui"
          type="radio"
          name="renew"
          value="1"
          validate={composeValidators(required)}
          onChange={(e) => userSubs && userSubs.length === 1 ? addAboNum(e.target.value) : null}
        />
        <Field
          classContainer="pl-3"
          component={Input}
          label="non"
          type="radio"
          name="renew"
          value="0"
          validate={composeValidators(required)}
          onChange={(e) => addAboNum(e.target.value)}
        />
      </div>
      {userSubs && userSubs.length > 1 &&
        <div className={"overflow-hidden transition-[max-height] ease-in-out duration-300 " + (values.renew === "1" ? " max-h-[350px]" : " max-h-[0px]")}>
          <table className="w-1/2">
            <thead>
              <tr>
                <td></td>
                <td className=" border-2 border-blue-darker px-2">Statut</td>
                <td className=" border-2 border-blue-darker px-2">Premier n°</td>
                <td className=" border-2 border-blue-darker px-2">Dernier n°</td>
                <td className=" border-2 border-blue-darker px-2">Dernier n° servi</td>
                <td className=" border-2 border-blue-darker px-2">Payeur</td>
              </tr>
            </thead>
            <tbody>
              {userSubs.map(sub => (
                <tr key={sub.ABO_NUM}>
                  <td className="px-2 border-2 border-blue-darker">
                    <Field
                      component={Input}
                      label="Renouveler"
                      type="radio"
                      name="abo"
                      value={`${sub.ABO_NUM}`}
                      validate={values.renew === "0" ? requiredAndNot0 : null}
                      // onChange={e => addAboNum(true, sub.ABO_NUM)}
                    />
                  </td>
                  <td className="px-2 border-2 border-blue-darker">{sub.STATUS_LIB}</td>
                  <td className="px-2 border-2 border-blue-darker">{sub.ABO_PNUM} {sub.Pdate ? `(${sub.Pdate.trim()})` : ""}</td>
                  <td className="px-2 border-2 border-blue-darker">{sub.EXPIRE} {sub.EXPIREDATE ? `(${sub.EXPIREDATE.trim()})` : ""}</td>
                  <td className="px-2 border-2 border-blue-darker">{sub.ABO_DNUM} {sub.Ddate ? `(${sub.Ddate.trim()})` : ""}</td>
                  <td className="px-2 border-2 border-blue-darker">{sub.NOM_NUM}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}