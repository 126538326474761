/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api`;

async function getIssue(action = "next", cursor = null, nb, search = null) {
  return await axiosInstance.axiosGetFetcher(`${baseUrl}/issue/?action=${action}${cursor ? "&cursor="+cursor : ""}&nb=${nb}${search ? "&search="+search : ""}`);
}

async function addIssueToCart(id) {
  return await axiosInstance.axiosPostFetcher(`${baseUrl}/cart/add/issue/${id}`);
}

export const issueService = {
  getIssue,
  addIssueToCart
};
