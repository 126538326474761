import { init, query, mutation } from '../../utils';
import { gql } from '@apollo/client';
import { cartSaleor } from './cart.saleor.service'
import { warehouseSaleor } from './warehouse.saleor.service';

async function orderCreateFromCard(req, res, token = null) {
    if (token == null) {
        token = cartSaleor.getCartToken(req, res);
        if (!token) {
            //TODO: throw error if no token
            return {};
        }
    }
    try {
        const data = await mutation({
            mutation: gql`
                mutation orderCreateFromCheckout($id: ID!, $checkoutId: String!) {
                    orderCreateFromCheckout(
                    id: $id
                    removeCheckout: true
                    metadata: [{key: "checkoutId", value: $checkoutId}]
                    ) {
                    order {
                        id
                        number
                        lines {
                            id
                            quantity
                        }
                    }
                    }
                }
            `,
            variables: {
                id: token,
                checkoutId: token
            },
            throwError: true,
            context: { app: true },
        })
        return data
    } catch (err) {
        console.log(err)
        throw err;
    }
}

async function createTransaction(orderId, paymentData) {
    try {
        const data = await mutation({
            mutation: gql`
            mutation TransactionCreate(
                $id: ID!
                $transaction: TransactionCreateInput!
                $transactionEvent: TransactionEventInput
            ) {
                transactionCreate(
                    id: $id
                    transaction: $transaction
                    transactionEvent: $transactionEvent
                ) {
                    transaction {
                        id
                    }
                    errors {
                        code
                        message
                    }
                }
            }
            
        `,
            variables: {
                id: orderId,
                transaction: {
                    type: "sotiaf.payment.system_pay",
                    status: "complete",
                    amountCharged: {
                        currency: paymentData.clientAnswer.orderDetails.orderCurrency,
                        amount: (paymentData.clientAnswer.orderDetails.orderTotalAmount / 100)
                    },
                    availableActions: ["REFUND"],
                    reference: paymentData.clientAnswer.transactions[0].transactionDetails.cardDetails.legacyTransId
                },
                transactionEvent: {
                    status: "SUCCESS",
                    name: "Charged"
                }
            },
            throwError: true,
            context: { app: true },
        })
        return data
    } catch (err) {
        throw err;
    }
}


async function orderMarkAsPaid(orderId, paymentData) {
    try {
        const data = await mutation({
            mutation: gql`
                mutation OrderMarkAsPaid($id: ID!, $transactionReference: String) {
                    orderMarkAsPaid(id: $id, transactionReference: $transactionReference){
                        order {
                            id
                        }
                    }  
                }
            `,
            variables: {
                id: orderId,
                transactionReference: paymentData.clientAnswer.transactions[0].transactionDetails.cardDetails.legacyTransId
            },
            throwError: true,
            context: { app: true },
        })
        return data
    } catch (err) {
        throw err;
    }
}


async function fulfillOrder(orderData) {
    // TODO: RECUPERER L'ID DE L'ENTREPROT

    let warehouse = await warehouseSaleor.getWarehouse()
    let warehouseId = warehouse.edges[0].node.id

    let lines = orderData.lines.map(line => {
        return {
            orderLineId: line.id,
            stocks: [
                {
                    quantity: line.quantity,
                    warehouse: warehouseId
                }
            ]
        }
    })
    try {
        const data = await mutation({
            mutation: gql`
            mutation FulfillOrder($orderId: ID!, $input: OrderFulfillInput!) {
                orderFulfill(order: $orderId, input: $input) {
                    order {
                        id
                    }
                }
            }
            `,
            variables: {
                orderId: orderData.id,
                input: {
                    lines: lines,
                    notifyCustomer: true,
                    allowStockToBeExceeded: true
                },
            },
            throwError: true,
            context: { app: true },
        })
        return data
    } catch (err) {
        console.log(err)
        throw err;
    }
}






export const orderSaleor = { orderCreateFromCard, createTransaction, orderMarkAsPaid, fulfillOrder }