import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
const baseUrl = `${serverRuntimeConfig.API_PRESSE_URL}/state`;

import { axiosInstance } from '../../utils';

const getStatesByCode = async (code = "FR") => {
  try {
    return await axiosInstance.axiosGetFetcher(`${baseUrl}/${code}`);
  } catch (err) {
    throw err;
  }
};

export const statePresseService = { getStatesByCode };
