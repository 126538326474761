import { gql } from '@apollo/client';
import { query } from '../../utils';

async function getPluginConfiguration(pluginId) {
  try {
    const data = await query({
      query: gql`
        query plugin($pluginId: ID!) {
          plugin(id: $pluginId) {
            channelConfigurations {
              active
              configuration {
                name
                value
              }
            }
          }
        }
      `,
      variables: { pluginId: pluginId },
      context: { app: true },
      throwError: true,
    }).catch((err) => {
      throw err;
    });

    return data;
  } catch (err) {
    throw err;
  }
}

export const pluginSaleor = {
  getPluginConfiguration,
};
