/* OUR CODE IMPORT */
import { axiosInstance } from '../utils';

const baseUrl = `/api`;

async function getStatesByCode(code) {
  return await axiosInstance.axiosGetFetcher(`${baseUrl}/state/${code}`);
}

export const stateService = {
  getStatesByCode
};
