import { composeValidators, required } from '../../../utils';

import { Input } from '../../form';
import { Field } from 'react-final-form';

const PAYMENT_TYPE_ID = 'saleor.payments.stripe';

export function Stripe({ onClick, selectedPaymentMethod }) {
  return (
    <>
      <Field
        component={Input}
        key={PAYMENT_TYPE_ID}
        label={'Stripe - Not Working'}
        type="radio"
        name="gateway"
        value={PAYMENT_TYPE_ID}
        onClick={onClick}
        validate={composeValidators(required)}
      />
      {selectedPaymentMethod == PAYMENT_TYPE_ID && (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-8">
            <Field
              component={Input}
              key="cardNumber"
              label="Numéro de carte"
              name="stripe[cardNumber]"
              placeHolder="1234-5678-XXXX-XXXX"
              type="text"
              validate={composeValidators(required)}
            />
          </div>
          <div className="col-span-2">
            <Field
              component={Input}
              key="expirationDate"
              label="Date d'expiration"
              name="stripe[expirationDate]"
              placeHolder="MM/YY"
              type="text"
              validate={composeValidators(required)}
            />
          </div>
          <div className="col-span-2">
            <Field
              component={Input}
              key="cvv"
              label="CVC/CVV"
              name="stripe[cvv]"
              placeHolder="Security code"
              type="text"
              validate={composeValidators(required)}
            />
          </div>
        </div>
      )}
    </>
  );
}
