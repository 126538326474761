export function BillingShipping({ subscription, user }) {
  return (
    <div className={subscription.action !== "gift" && subscription?.offer?.TYPE_OFFER === "digital" ? "grid grid-cols-1 gap-4" : "grid grid-cols-2 gap-4"}>
      <div className="w-full text-xl mt-10">
        <h2 className="text-3xl text-blue-dark flex mt-10 mb-3 italic ">
          <span className="hidden xs:inline w-40 h-1 bg-blue-dark
          self-center mr-10"></span>
          {subscription.action !== "gift" ? "Vos coordonnées" : "Adresse de facturation"}
        </h2>
        {user?.defaultBillingAddress?.lastName && 
          <p>Nom : {user?.defaultBillingAddress?.lastName}</p>
        }
        {user?.defaultBillingAddress?.firstName && 
          <p>Prénom : {user?.defaultBillingAddress?.firstName}</p>
        }
        {user?.defaultBillingAddress?.companyName && 
          <p className="mt-5">Société : {user?.defaultBillingAddress?.companyName}</p>
        }
        {user?.defaultBillingAddress?.streetAddress1 && 
          <p className={user?.defaultBillingAddress?.companyName ? "" : "mt-5"}>Adresse 1 : {user?.defaultBillingAddress?.streetAddress1}</p>
        }
        {user?.defaultBillingAddress?.streetAddress2 && 
          <p>Adresse 2 : {user?.defaultBillingAddress?.streetAddress2}</p>
        }
        {user?.defaultBillingAddress?.postalCode && 
          <p>Code Postal : {user?.defaultBillingAddress?.postalCode}</p>
        }
        {user?.defaultBillingAddress?.city && 
          <p>Ville : {user?.defaultBillingAddress?.city}</p>
        }
        {user?.defaultBillingAddress?.country?.country && 
          <p>Pays : {user?.defaultBillingAddress?.country?.country}</p>
        }
        {user?.email && 
          <p className="mt-5">Adresse Email : {user?.email}</p>
        }
      </div>
      {(subscription.action === "gift" && subscription?.offer?.TYPE_OFFER !== "digital") &&
        <div className="w-full text-xl mt-10">
          <h2 className="text-3xl text-blue-dark flex mt-10 mb-3 italic ">
            <span className="hidden xs:inline w-40 h-1 bg-blue-dark
            self-center mr-10"></span>
            Adresse de livraison
          </h2>
          {subscription?.recipientAdr?.NOM_NUM && 
            <p>Nom : {subscription?.recipientAdr?.NOM_NUM}</p>
          }
          {subscription?.recipientAdr?.PRENOM_NUM?.trim() && 
            <p>Prénom : {subscription?.recipientAdr?.PRENOM_NUM}</p>
          }
          {user?.defaultBillingAddress?.ADR_COMP1?.trim() && 
            <p className="mt-5">Société : {subscription?.recipientAdr?.ADR_COMP1}</p>
          }
          {subscription?.recipientAdr?.ADR_ADR && 
            <p className={user?.defaultBillingAddress?.ADR_COMP1?.trim() ? "" : "mt-5"}>Adresse 1 : {subscription?.recipientAdr?.ADR_ADR}</p>
          }
          {subscription?.recipientAdr?.ADR_COMP2?.trim() && 
            <p>Adresse 2 : {subscription?.recipientAdr?.ADR_COMP2}</p>
          }
          {subscription?.recipientAdr?.ADR_CP && 
            <p>Code Postal : {subscription?.recipientAdr?.ADR_CP}</p>
          }
          {subscription?.recipientAdr?.ADR_VILLE && 
            <p>Ville : {subscription?.recipientAdr?.ADR_VILLE}</p>
          }
          {subscription?.recipientAdr?.MUM_PAYS && 
            <p>Pays : {subscription?.recipientAdr?.MUM_PAYS}</p>
          }
          {subscription?.recipientAdr?.ADR_E_MAIL?.trim() && 
            <p className="mt-5">Adresse Email : {subscription?.recipientAdr?.ADR_E_MAIL}</p>
          }
        </div>
      }
      {(subscription.action === "gift" && subscription?.offer?.TYPE_OFFER === "digital") &&
        <div className="w-full text-xl mt-10">
          <h2 className="text-3xl text-blue-dark flex mt-10 mb-3 italic ">
            <span className="hidden xs:inline w-40 h-1 bg-blue-dark
            self-center mr-10"></span>
            Abonnement numérique
          </h2>
          <p>Vous avez choisi d'offrir un abonnement numérique</p>
        </div>
      }
    </div>
  );
}