import { query } from '../../utils';
import { gql } from '@apollo/client';


async function getWarehouse() {
    try {
        const warehouse = await query({
            query: gql`
                query Warehouses {
                    warehouses(first: 1) {
                        edges {
                            node {
                                id
                            }
                        }
                    }
                }
            `,
            throwError: true,
            fetchPolicy: 'no-cache',
            context: { app: true },
        }).catch((err) => {
            throw err;
        });
        return warehouse
    } catch (err) {
        console.log(err)
        throw err;
    }
}



export const warehouseSaleor = { getWarehouse }